import { CommonComponents } from '@3as-affiliates/affiliates/web/config';
import { useChatBotContext } from '@3as-affiliates/affiliates/web/features/chat-bot';
import { useClickAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import { Button, colors } from '@soluto-private/mx-asurion-ui-react';
import Lottie from 'lottie-react';
import styled from 'styled-components';
import { EnrollmentButton } from '../enrollment-button';
import ChatIcon from './assets/chat-icon.json';

const Container = styled.div`
  position: fixed;
  bottom: 0px;
  padding-bottom: 16px;
  padding-top: 16px;
  display: flex;
  max-width: 1440px;
  margin: auto;
  width: calc(100% - 32px);
  justify-content: center;
  background: ${colors.white};
  border-top: 1px solid ${colors.neutralBrightest};
`;

const StartChatButton = styled(Button)`
  height: 65px !important;
  padding: 0px !important;
  margin: 0px 16px !important;
`;

interface Props {
  pageName: string;
}

export const SignedInBottomStickySection = ({ pageName }: Props) => {
  const { openChatBot } = useChatBotContext();
  const { dispatchClickAnalytics } = useClickAnalytics();

  const onStartChatClick = () => {
    dispatchClickAnalytics(
      {
        Cta: 'Open chat',
        ActionId: 'HomePageChatButton',
      },
      {
        Component: CommonComponents.ChatBot,
      }
    );
    openChatBot();
  };

  return (
    <Container>
      <StartChatButton variant="flat" onClick={onStartChatClick}>
        <Lottie
          loop
          autoplay
          animationData={ChatIcon}
          style={{ width: 65, height: 65 }}
        />
      </StartChatButton>
      <EnrollmentButton
        buttonVariant="default"
        size="large"
        analyticsExtras={{
          PageName: pageName,
          buttonPosition: 'stickyBottom',
        }}
      />
    </Container>
  );
};
