import { useAppData } from '@3as-affiliates/shared/web/data-access/data-store';
import { useClickAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import {
  AuthenticationState,
  useAuth,
} from '@3as-affiliates/shared/web/utils/auth-provider';
import { Button } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { AvatarMenu } from '../avatar-menu';
import { PartnerLogo, PartnerLogoProps } from '../partner-logo';

const Container = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 19px 19px 19px 16px;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: 19px 19px 19px 16px;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 0;
  align-items: center;
`;

type HeaderProps = PartnerLogoProps & {
  pageName: string;
  showLoginButton?: boolean;
  showUserAvatar?: boolean;
  onProfileClick?: () => void;
  onProvideFeedbackClick?: () => void;
};

export const Header = ({
  pageName,
  showLoginButton = false,
  showUserAvatar = false,
  logoName,
  logoHeight,
  onProfileClick,
  onProvideFeedbackClick,
}: HeaderProps) => {
  const { logIn, authState, logOut } = useAuth();
  const { dispatchClickAnalytics } = useClickAnalytics();
  const { affiliateInfo } = useAppData();

  const headerClick = () =>
    dispatchClickAnalytics(
      {
        Cta: 'Header Icon',
        ActionId: 'HeaderIcon',
      },
      {
        PageName: pageName,
      }
    );

  const loginClick = () => {
    dispatchClickAnalytics(
      {
        Cta: 'Header login button',
        ActionId: 'HeaderLoginButton',
      },
      {
        PageName: pageName,
      }
    );
    logIn();
  };

  const logoutClick = () => {
    dispatchClickAnalytics(
      {
        Cta: 'Header logout button',
        ActionId: 'HeaderLogoutButton',
      },
      {
        PageName: pageName,
      }
    );

    logOut();
  };

  const isLoggedIn: boolean = authState === AuthenticationState.LoggedIn;

  return (
    <Container onClick={headerClick}>
      <LeftContainer>
        <PartnerLogo logoName={logoName} logoHeight={logoHeight} />
      </LeftContainer>
      <RightContainer>
        {showLoginButton && !isLoggedIn && (
          <Button
            onClick={loginClick}
            variant="outline"
            size="small"
            iconSrc="User"
            iconSide="left"
            style={{ height: '27px', fontSize: '14px' }}
          >
            Sign in
          </Button>
        )}
        {showUserAvatar && isLoggedIn && (
          <AvatarMenu
            email={affiliateInfo?.email as string}
            onProfileClick={onProfileClick}
            onProvideFeedbackClick={onProvideFeedbackClick}
            onSignOut={logoutClick}
          />
        )}
      </RightContainer>
    </Container>
  );
};
