import styled from 'styled-components';
import BannerImage from './assets/banner-image.svg';

const Logo = styled.img`
  max-width: 600px;
  width: 100%;
  background-size: contain;
  margin: auto;
`;

export const PageTopBanner = () => (
  <Logo src={BannerImage} alt="Asurion Affiliates banner" />
);
