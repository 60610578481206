/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnalyticsEventModel, EventModelWriter } from 'shisell';
import { APIWriter } from './apiWriter';
import { ConsoleWriter } from './consoleWriter';
import { FullStoryWriter } from './fullStoryWriter';

const nonProdWriters = [APIWriter, ConsoleWriter];

const prodWriters = [APIWriter, FullStoryWriter];

export const registerLocalWriters: (
  isProd: boolean
) => EventModelWriter<void> =
  (isProd) =>
  async (eventModel: AnalyticsEventModel): Promise<any> => {
    const enabledWritersArray = isProd ? prodWriters : nonProdWriters;
    try {
      return await Promise.all(
        enabledWritersArray.map((writer) => writer(eventModel, isProd))
      );
    } catch (err: any) {
      console.warn('failed to track', err.message);
      return;
    }
  };
