import {
  affiliatesAndroidAppUrl,
  affiliatesIosAppUrl,
} from '@3as-affiliates/affiliates/web/config';
import { NotificationModal } from '@3as-affiliates/affiliates/web/features/notification-modal';
import { openLink } from '@3as-affiliates/affiliates/web/native-app-libs';
import { isAndroidDevice } from '@3as-affiliates/affiliates/web/utils/user-device-info';
import { useClickAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import { Button } from '@soluto-private/mx-asurion-ui-react';
import { useEffect, useState } from 'react';

export const MobileAppInstallModal = () => {
  const { dispatchClickAnalytics } = useClickAnalytics();
  const [isAppModalOpen, setIsAppModalOpen] = useState(false);
  const MODAL_SHOWN_ON = 'appInstallModalShownOn';

  useEffect(() => {
    function isModalShownToday(modalShownDate: Date) {
      const today = new Date();
      return (
        modalShownDate.getFullYear() === today.getFullYear() &&
        modalShownDate.getMonth() === today.getMonth() &&
        modalShownDate.getDate() === today.getDate()
      );
    }

    const modalShown = localStorage.getItem(MODAL_SHOWN_ON);
    if (modalShown) {
      const modalShownDate = new Date(parseInt(modalShown));
      if (!isModalShownToday(modalShownDate)) {
        setIsAppModalOpen(true);
      }
    } else {
      setIsAppModalOpen(true);
      storeModalShownDate();
    }
  }, []);

  function storeModalShownDate() {
    localStorage.setItem(MODAL_SHOWN_ON, new Date().getTime().toString());
  }

  function closeAppInstallModal() {
    setIsAppModalOpen(false);
  }

  const installAppButtonClick = () => {
    const actionId = isAndroidDevice()
      ? 'installAppFromGooglePlayStoreButton'
      : 'installAppFromAppStoreButton';

    dispatchClickAnalytics({
      Cta: 'Install app modal',
      ActionId: actionId,
    });

    const linkUrl = isAndroidDevice()
      ? affiliatesAndroidAppUrl
      : affiliatesIosAppUrl;

    openLink(linkUrl);

    storeModalShownDate();
    closeAppInstallModal();
  };

  const getActionComponent = () => {
    const buttonText = isAndroidDevice()
      ? 'Go to Google Play store'
      : 'Go to the App Store';

    return (
      <Button variant="default" onClick={installAppButtonClick}>
        {buttonText}
      </Button>
    );
  };

  return (
    <NotificationModal
      isOpen={isAppModalOpen}
      title="Big news!"
      content="There’s now a dedicated app! This means better performance, faster updates and upcoming features. Download the app now."
      actionComponent={getActionComponent()}
      closeModal={closeAppInstallModal}
      modalAriaLabel="Installation Mobile App modal"
      modalStyle={{ width: '288px' }}
    />
  );
};
