import styled from 'styled-components';

const CopyrightContent = styled.div`
  flex: 1;
  margin-top: 10px;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;
`;

export const CopyRight = () => {
  const currYear = new Date().getFullYear();

  return (
    <CopyrightContent>
      © Asurion 1992-{currYear}. All rights reserved.
    </CopyrightContent>
  );
};
