import Cookies from 'js-cookie';
import UAParser from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid';

const ua = new UAParser().getResult();
const USER_BROWSER_KEY = 'UserBrowserId';

interface UserBrowser {
  UserBrowserId: string;
  Partner?: string;
  Os?: string;
  OsVersion?: string;
  Vendor?: string;
  Model?: string;
  BrowserType?: string;
  BrowserVersion?: string;
  DeviceType?: string;
}

export const getUserBrowserInfo = (): UserBrowser => {
  const UserBrowserId =
    localStorage.getItem(USER_BROWSER_KEY) ||
    Cookies.get(USER_BROWSER_KEY) ||
    uuidv4();
  localStorage.setItem(USER_BROWSER_KEY, UserBrowserId);
  Cookies.set(USER_BROWSER_KEY, UserBrowserId);

  return {
    UserBrowserId,
    Os: ua.os.name,
    OsVersion: ua.os.version,
    Vendor: ua.device.vendor,
    Model: ua.device.model,
    BrowserType: ua.browser.name,
    BrowserVersion: ua.browser.version,
    DeviceType: ua.device.type,
  };
};
