import { AlertBanner } from '@3as-affiliates/affiliates/web/components';
import { HttpStatusCodes } from '@3as-affiliates/shared/utils/fetch-http-client';
import { ResType } from '@3as-affiliates/shared/web/data-access/api-client';
import styled from 'styled-components';
import { Routes } from '../router';

const LoginLink = styled.a`
  color: white;
  text-decoration: underline;
  cursor: pointer;
`;

interface SignupAlertBannerProps {
  error: ResType;
}

export const SignupAlertBanner = ({ error }: SignupAlertBannerProps) => {
  const userAlreadyExistsError = error.statusCode === HttpStatusCodes.Conflict;
  const message =
    error.errorMessage && userAlreadyExistsError ? (
      <>
        {error.errorMessage + ' Please '}
        <LoginLink href={Routes.LoggedOutLanding}>login.</LoginLink>
      </>
    ) : error.errorMessage ? (
      error.errorMessage
    ) : (
      'Error creating account. Please try again.'
    );

  return (
    <AlertBanner isOpen={true} bannerType="error" iconSrc="SystemAlertAlert">
      {message}
    </AlertBanner>
  );
};
