export function nativeAppLogin() {
  const postMsgLogin = JSON.stringify({
    transactionId: crypto.randomUUID(),
    body: JSON.stringify({
      query: `
        mutation nativeAppLogin {
          nativeAppLogin
        }`,
      variables: {},
    }),
  });

  // eslint-disable-next-line
  // @ts-ignore-next-line
  window.ReactNativeWebView.postMessage(postMsgLogin);
}

// todo: enhance this function to refresh the token
export function nativeAppLoginRefresh(refreshToken: string) {
  const postMsgLoginRefresh = JSON.stringify({
    transactionId: crypto.randomUUID(),
    body: JSON.stringify({
      query: `
        mutation nativeAppLoginRefresh($refreshToken: String!) { 
          nativeAppLoginRefresh(refreshToken: $refreshToken) 
        }`,
      variables: {
        refreshToken,
      },
    }),
  });

  // eslint-disable-next-line
  // @ts-ignore-next-line
  window.ReactNativeWebView.postMessage(postMsgLoginRefresh);
}
