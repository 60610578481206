import { CHAT_ROLES } from '@3as-affiliates/shared/types-configs';
import {
  ResType,
  useGetChatAnswer,
} from '@3as-affiliates/shared/web/data-access/api-client';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  cannedMessages,
  ChatBotContextValueProps,
  chatBotFirstTimeUserLocalStorageKey,
  ChatBotProviderProps,
  ConversationList,
  defaultWelcomeMessages,
  Message,
} from '../types-configs';

const ChatBotContext = createContext<ChatBotContextValueProps | undefined>(
  undefined
);

export const useChatBotContext = () => {
  const context = useContext(ChatBotContext);
  if (!context) {
    throw new Error('ChatBotContext error');
  }
  return context;
};

export const ChatBotProvider = ({ children }: ChatBotProviderProps) => {
  const { getUserChatAnswer } = useGetChatAnswer();

  const [chatBotOpen, setChatBotOpen] = useState(false);
  // the chat session id. reset with every new session
  const [chatSessionId, setChatSessionId] = useState('');
  const [waitingForUserAnswer, setWaitingForUserAnswer] = useState(false);

  const startChatSession = () => setChatSessionId(uuidv4());

  const endChatSession = () => setChatSessionId('');

  // conversation b/w Human & Assistant. reset with every new session
  const [conversationList, setConversationList] = useState<ConversationList>(
    []
  );

  const [cannedMessagesList, setCannedMessagesList] =
    useState<ConversationList>([]);

  const [answer, setAnswer] = useState<Message | undefined>(undefined);

  // any error in the conversation
  const [chatError, setChatError] = useState<ResType>({
    error: false,
    errorMessage: '',
  });

  // first time user flag.
  const [firstTimeUser, setFirstTimeUser] = useState(
    localStorage.getItem(chatBotFirstTimeUserLocalStorageKey) !== 'false'
  );

  const addMessageToConversationList = useCallback(
    (message: Message) => {
      const currentConversation = [...conversationList, message];
      setConversationList(currentConversation);
    },
    [conversationList]
  );

  useEffect(() => {
    if (!chatError.error) {
      setTimeout(() => {
        setChatError({ error: false, errorMessage: '' });
      }, 5000);
    }
  }, [chatError]);

  useEffect(() => {
    let timeoutId1: ReturnType<typeof setTimeout>,
      timeoutId2: ReturnType<typeof setTimeout>;
    if (conversationList.length === 1) {
      timeoutId1 = setTimeout(() => {
        addMessageToConversationList(defaultWelcomeMessages.welcomeMessage2);
      }, 500);
    } else if (conversationList.length === 2) {
      timeoutId2 = setTimeout(() => {
        setCannedMessagesList([
          cannedMessages.cannedMessage1,
          cannedMessages.cannedMessage2,
          cannedMessages.cannedMessage3,
        ]);
      }, 750);
    }
    return () => {
      clearTimeout(timeoutId1);
      clearTimeout(timeoutId2);
    };
  }, [conversationList, addMessageToConversationList]);

  useEffect(() => {
    if (answer) {
      addMessageToConversationList(answer);
      setAnswer(undefined);
    }
  }, [answer, addMessageToConversationList]);

  const openChatBot = () => {
    startChatSession();
    setChatBotOpen(true);
    addMessageToConversationList(defaultWelcomeMessages.welcomeMessage1);
  };

  const closeChatBot = () => {
    endChatSession();
    setConversationList([]);
    setChatBotOpen(false);
    setWaitingForUserAnswer(false);
  };

  const updateFirstTimeUser = () => {
    setFirstTimeUser(false);
    localStorage.setItem(chatBotFirstTimeUserLocalStorageKey, 'false');
  };

  const getAnswerForUserQuestion = async (question: string) => {
    setWaitingForUserAnswer(true);
    if (cannedMessagesList.length > 0) {
      setCannedMessagesList([]);
    }

    const newQuestion = {
      role: CHAT_ROLES.HUMAN,
      content: question,
    };

    addMessageToConversationList(newQuestion);

    const answerMessageRes = await getUserChatAnswer(
      [newQuestion],
      chatSessionId
    );

    if (answerMessageRes.error) {
      setChatError({
        error: answerMessageRes.error,
        errorMessage: answerMessageRes.errorMessage,
      });
    } else {
      setAnswer(answerMessageRes.data as Message);
    }
    setWaitingForUserAnswer(false);
  };

  return (
    <ChatBotContext.Provider
      value={{
        firstTimeUser,
        updateFirstTimeUser,
        isChatBotOpen: chatBotOpen,
        openChatBot,
        closeChatBot,
        chatError,
        chatSessionId,
        conversationList,
        getAnswerForUserQuestion,
        waitingForUserAnswer,
        cannedMessagesList,
      }}
    >
      {children}
    </ChatBotContext.Provider>
  );
};
