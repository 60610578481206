import { isMobileApp } from '@3as-affiliates/affiliates/web/native-app-libs';
import {
  AuthProviderProps,
  WebAppAuthProvider,
} from '@3as-affiliates/shared/web/utils/auth-provider';
import { MobileAppAuthProvider } from './MobileAppAuthProvider';

export const AuthProvider: React.FC<AuthProviderProps> = ({
  authOpenIdConfig,
  isProd,
  children,
}) => {
  if (isMobileApp()) {
    return (
      <MobileAppAuthProvider
        isProd={isProd}
        authOpenIdConfig={authOpenIdConfig}
      >
        {children}
      </MobileAppAuthProvider>
    );
  }
  return (
    <WebAppAuthProvider authOpenIdConfig={authOpenIdConfig} isProd={isProd}>
      {children}
    </WebAppAuthProvider>
  );
};
