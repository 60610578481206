import {
  CommonComponents,
  CommonSections,
} from '@3as-affiliates/affiliates/web/config';
import { useClickAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import { AlertBanner, TextField } from '@soluto-private/mx-asurion-ui-react';
import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { useChatBotContext } from '../../provider';
import { ConversationInnerWindow } from './ConversationInnnerWindow';

const Container = styled.div`
  display: flex;
  height: calc(100vh - 150px);
  flex-direction: column;
`;

const TextFieldWrapper = styled.div`
  margin-bottom: 20px;
`;
export function ConversationMainWindow() {
  const { chatSessionId, getAnswerForUserQuestion, chatError } =
    useChatBotContext();

  const { dispatchClickAnalytics } = useClickAnalytics();

  const [userQuestion, setUserQuestion] = useState('');

  const handleNewQuestionSubmit = () => {
    if (!userQuestion) return;
    dispatchClickAnalytics(
      {
        Cta: 'NewMessageInputSubmitted',
        ActionId: 'NewMessageInputSubmitted',
      },
      {
        Component: CommonComponents.ChatBot,
        Section: CommonSections.ChatBotFirstTimeOverview,
        ChatSessionId: chatSessionId,
      }
    );
    getAnswerForUserQuestion(userQuestion);
    setUserQuestion('');
  };

  const handleUserQuestionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserQuestion(e.target.value);
  };

  const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleNewQuestionSubmit();
    }
  };

  return (
    <Container>
      <AlertBanner isOpen={!!chatError.error} alertBannerType="warn">
        {chatError.errorMessage}
      </AlertBanner>
      <ConversationInnerWindow />
      <TextFieldWrapper>
        <TextField
          label="Type a message"
          value={userQuestion}
          className="fs-mask"
          affixRight={'Send'}
          affixRightClickable={true}
          affixLeftClickable={true}
          onChange={handleUserQuestionChange}
          onAffixRightClick={handleNewQuestionSubmit}
          onKeyDown={handleEnterKey}
        />
      </TextFieldWrapper>
    </Container>
  );
}
