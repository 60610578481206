import { ChatBotRequest } from '@3as-affiliates/shared/types-configs';
import { fetchHttpClient } from '@3as-affiliates/shared/utils/fetch-http-client';
import { useApiCommons } from '../utils/useApiCommons';

const baseUrl = process.env['NX_AFFILIATE_SERVICES_BASE_URL'];

export const useChatApi = () => {
  const { getCommonHeaders } = useApiCommons();

  const getChatAnswer = async (chatContext: ChatBotRequest) => {
    const headers = await getCommonHeaders();

    const response = await fetchHttpClient.post(
      `${baseUrl}/chats/${chatContext.sessionId}`,
      {
        body: chatContext,
        headers,
      }
    );
    return response;
  };

  return { getChatAnswer };
};
