import { jwtDecode, JwtPayload } from 'jwt-decode';
import { Persona } from '../config';

type JWTDecodeResponse = JwtPayload & {
  email: string;
  asurion_id: string;
  principal_identifier: string;
  personas: Persona[];
};

type ResData = {
  asurionId: string;
  email: string;
  personas: Persona[];
};

export const parseJWTToken = (token: string): ResData => {
  try {
    const decodedData = jwtDecode(token) as JWTDecodeResponse;
    const asurionId = decodedData?.asurion_id;
    const email = decodedData?.principal_identifier;
    if (!asurionId || !email) {
      throw new Error('Invalid login response');
    }
    const personas = decodedData?.personas;
    return { asurionId, email, personas };
  } catch (error) {
    throw new Error('Invalid login response');
  }
};
