import { breakpoints, colors, Text } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import ProgramInfo from './assets/program-info.svg';
import { CheckList } from './CheckList';

const ProgramInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
`;

const ProgramInfoTitle = styled(Text)`
  font-weight: 300 !important;
  align-self: stretch;
  ${breakpoints.md} {
    align-self: center;
  }
  padding-bottom: 16px;
`;

const ProgramInfoSubText = styled(Text)`
  font-weight: 300 !important;
  padding-bottom: 16px;
`;

const ProgramInfoImage = styled.img`
  margin-bottom: 16px;
  width: 100%;
  ${breakpoints.md} {
    width: auto;
  }
`;

export const ProgramInfoSection = () => {
  return (
    <ProgramInfoWrapper>
      <ProgramInfoImage src={ProgramInfo} alt="What's appliance plus" />
      <ProgramInfoTitle size={5} color={colors.black}>
        What's Appliance+?
      </ProgramInfoTitle>
      <ProgramInfoSubText size={3} color={colors.black}>
        Appliance+ protects all home appliances. No matter where or when
        customers bought them.
      </ProgramInfoSubText>
      <ProgramInfoSubText size={2} color={colors.black}>
        $29.99/month when customers sign up with a Tech, compared to
        $34.99/month when they sign up online or over the phone.
      </ProgramInfoSubText>
      <CheckList />
    </ProgramInfoWrapper>
  );
};
