export enum Routes {
  AuthRedirectCallback = '/redirect',
  LoggedInHome = '/home',
  LoggedOutLanding = '/landing',
  Signup = '/sign-up',
  MSA = '/msa',
}

export const defaultLoggedInRoute = Routes.LoggedInHome;

export const defaultLoggedOutRoute = Routes.LoggedOutLanding;
