import { useAppData } from '@3as-affiliates/shared/web/data-access/data-store';
import { Callout } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import ShieldIcon from './assets/shield.svg';

const WelcomeCallout = styled(Callout)`
  margin-bottom: 16px;
  padding: 16px;
`;

const Icon = styled.img`
  width: 35px !important;
  height: 43px !important;
`;

export const WelcomeSection = () => {
  const { affiliateInfo } = useAppData();

  const heading = `Welcome${
    affiliateInfo ? ', ' + affiliateInfo.firstName : ''
  }!`;

  const content = "You're all set to sell Appliance+";

  return (
    <WelcomeCallout
      heading={heading}
      content={content}
      media={<Icon src={ShieldIcon} />}
    />
  );
};
