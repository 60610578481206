import { PageNames } from '@3as-affiliates/affiliates/web/config';
import { AuthRedirectCallback } from '@3as-affiliates/shared/web/features';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { LandingPage, MSAPage, SignupPage } from '../pages';
import { AuthStateRoute, RouteWithAffiliateInfo } from './private-routes';
import { defaultLoggedOutRoute, Routes } from './routesConfig';

export const router = createBrowserRouter([
  {
    path: Routes.AuthRedirectCallback,
    element: (
      <AuthRedirectCallback
        homePageRoute={Routes.LoggedInHome}
        pageName={PageNames.AuthRedirectPage}
      />
    ),
  },
  {
    path: Routes.LoggedOutLanding,
    element: <LandingPage />,
  },
  {
    path: Routes.LoggedInHome,
    element: <RouteWithAffiliateInfo />,
  },
  {
    path: Routes.Signup,
    element: <AuthStateRoute Component={SignupPage} />,
  },
  {
    path: Routes.MSA,
    element: <MSAPage />,
  },
  {
    path: '*',
    element: <Navigate to={defaultLoggedOutRoute} />,
  },
]);
