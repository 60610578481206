import { NotificationModal } from '@3as-affiliates/affiliates/web/features/notification-modal';
import { useClickAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import { useAuth } from '@3as-affiliates/shared/web/utils/auth-provider';
import { Button } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

type DeleteAccountModalProps = {
  showDeleteAccountModal: boolean;
  hideDeleteAccountModal: () => void;
};

export const DeleteAccountModal = ({
  showDeleteAccountModal,
  hideDeleteAccountModal,
}: DeleteAccountModalProps) => {
  const { logOut } = useAuth();
  const { dispatchClickAnalytics } = useClickAnalytics();

  const cancelDeleteAccount = () => {
    dispatchClickAnalytics({
      Cta: 'Delete Account Cancelled',
      ActionId: 'deleteAccountCancelled',
    });
    hideDeleteAccountModal();
  };

  const confirmDeleteAccount = () => {
    dispatchClickAnalytics({
      Cta: 'Delete Account Confirmed',
      ActionId: 'deleteAccountConfirmed',
    });
    hideDeleteAccountModal();
    logOut();
  };

  const actionComponent = (
    <Container>
      <Button variant="outline" onClick={cancelDeleteAccount}>
        Cancel
      </Button>
      <Button variant="default" onClick={confirmDeleteAccount}>
        Yes, delete
      </Button>
    </Container>
  );

  return (
    <NotificationModal
      isOpen={showDeleteAccountModal}
      title="Are you sure?"
      content={
        'Are you sure you want to delete your account? This action cannot be undone. Once submitted it will take 24-48 hours to delete your account. You will get a confirmation email once your account has been deleted. Questions? Contact us at support@asurion.com or 1-615-961-6680'
      }
      actionComponent={actionComponent}
      closeModal={cancelDeleteAccount}
      modalAriaLabel="Delete user account modal"
      modalStyle={{ width: '288px' }}
      delayToShowModal={0}
    />
  );
};
