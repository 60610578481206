import { PageNames } from '@3as-affiliates/affiliates/web/config';
import { PageContainerWithViewAnalytics } from '@3as-affiliates/shared/web/components';
import { Icon } from '@soluto-private/mx-asurion-ui-react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const PdfContainer = styled.iframe`
  height: calc(100vh - 200px);
  width: 100%;
`;

const HeaderContainer = styled.div`
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BackContainer = styled.div`
  padding: 0.75rem 1.25rem;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const BackText = styled.span`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

export const MSAPage = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <PageContainerWithViewAnalytics pageName={PageNames.MSAPage}>
      <HeaderContainer>
        <BackContainer onClick={goBack}>
          <Icon src="ChevronLeft" size="normal" fill="#000000" />
          <BackText>Back</BackText>
        </BackContainer>
      </HeaderContainer>
      <PdfContainer src="../assets/msa/Asurion_AppliancePlus_Master_Services_Agreement_2023_V1.pdf" />
    </PageContainerWithViewAnalytics>
  );
};
