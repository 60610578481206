import styled from 'styled-components';

const SubTitle = styled.p<{ textAlign: string; fontSize: string }>`
  font-size: ${({ fontSize }) => fontSize};
  line-height: 130%;
  font-weight: 300;
  padding: 8px 16px;
  margin: 0px;
  text-align: ${({ textAlign }) => textAlign};
`;

interface Props {
  subTitle: string;
  textAlign?: string;
  fontSize?: string;
}

export const SectionSubTitle = ({
  subTitle,
  textAlign = 'left',
  fontSize = '20px',
}: Props) => (
  <SubTitle textAlign={textAlign} fontSize={fontSize}>
    {subTitle}
  </SubTitle>
);
