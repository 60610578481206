import styled from 'styled-components';

const Icon = styled.img`
  height: 74px;
  width: 283px;
  margin: auto;
`;

interface Props {
  iconSrc: string;
  iconAlt: string;
}

export const RatingsItem = ({ iconSrc, iconAlt }: Props) => (
  <Icon src={iconSrc} alt={iconAlt} />
);
