export enum AWS_ACCOUNTS {
  NON_PROD_AWS_ACCOUNT = '661155341687',
  PROD_AWS_ACCOUNT = '181208912998',
}

export enum AWS_REGIONS {
  PRIMARY_REGION = 'us-east-1',
}

export enum AWS_SECRET_NAMES {
  CHAT_BOT_AUTHORIZATION_SECRET = 'chatbot-authorization-secret',
}

export enum DYNAMODB_TABLES {
  NON_PROD_SANDBOX = 'AffiliatesDynamoSandbox-AffiliatesTable37673CF3-11VNLFABF8GW2',
  NON_PROD = 'AffiliatesDynamoNonprod-AffiliatesTable37673CF3-HA42RRLN773P',
  PROD = 'AffiliatesDynamoProd-AffiliatesTable37673CF3-8T4ERFZEQ2PR',
}

export enum Environments {
  DEV = 'dev',
  PROD = 'prod',
  FEAT = 'feat',
}

interface Tags {
  CLIENT: string;
  BUSINESS_UNIT: string;
  BUSINESS_REGION: string;
  PLATFORM: string;
  EMAIL_DISTRIBUTION: string;
  PRODUCT: string;
  [key: string]: string;
}

const tags: Tags = {
  PRODUCT: 'Asurion-Affiliates',
  CLIENT: 'MULTI_TENANT',
  BUSINESS_UNIT: 'PSS',
  PLATFORM: '3AS-AFFILIATE',
  BUSINESS_REGION: 'NORTHAMERICA',
  EMAIL_DISTRIBUTION: '3as-affiliate-devs@asurion.com',
};

export const getAwsTags = (additionalTags = {}) => ({
  ...tags,
  ...additionalTags,
});

export const getIamRoleName = (env: string, accountId: string): string =>
  env === Environments.PROD
    ? `arn:aws:iam::${accountId}:role/affiliates-services-prod-${AWS_REGIONS.PRIMARY_REGION}-lambdaRole`
    : `arn:aws:iam::${accountId}:role/affiliates-services-dev-${AWS_REGIONS.PRIMARY_REGION}-lambdaRole`;

const vpcSecurityGroupIds = {
  dev: ['sg-09c20b0be24b5c41a'],
  prod: ['sg-0613b51e5a5122209'],
};

const vpcSubnetIds = {
  dev: [
    'subnet-0ebf7354fe8c143f4',
    'subnet-05c7d78137af65850',
    'subnet-0ae744d028750a344',
  ],
  prod: [
    'subnet-01a23b1f29df60b9a',
    'subnet-08012afe570c1a2da',
    'subnet-0d918f01c17a195a4',
  ],
};

export const getVpcSecurityGroupIds = (env: string): Array<string> =>
  env === Environments.PROD
    ? vpcSecurityGroupIds[env]
    : vpcSecurityGroupIds[Environments.DEV] || [];

export const getVpcSubnetIds = (env: string): Array<string> =>
  env === Environments.PROD
    ? vpcSubnetIds[env]
    : vpcSubnetIds[Environments.DEV] || [];
