import { Text } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import DeviceInHand from './device-in-hand.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 16px 16px 16px;
  gap: 8px;
  background: linear-gradient(180deg, #f0f0f5 0%, #fff 100%), #fff;
`;

const SalesSuccessImage = styled.img`
  border-radius: 4px;
  margin-top: 16px;
  width: 90%;
  max-width: 450px;
  align-self: center;
`;

export const SalesSuccess = () => (
  <Container>
    <Text size={4} weight={'feather'}>
      Sales success starts with the app
    </Text>
    <Text size={2} weight={'feather'} style={{ lineHeight: '1.3rem' }}>
      Easily enroll customers with a few taps or share your personalized sales
      link. It's never been this simple to expand your sales network.
    </Text>
    <SalesSuccessImage
      src={DeviceInHand}
      alt={
        'Person holding mobile device with the Asurion Affiliates app in display'
      }
    />
  </Container>
);
