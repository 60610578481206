import { useEffect } from 'react';
import styled from 'styled-components';
import { useChatBotContext } from '../provider';
import { ChatbotContainer } from './ChatbotContainer';

const OverlayDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  will-change: opacity;
`;

export function ChatBot() {
  const { isChatBotOpen } = useChatBotContext();

  useEffect(() => {
    isChatBotOpen && document.body.classList.add('chat-no-scroll');
    return () => {
      document.body.classList.remove('chat-no-scroll');
    };
  }, [isChatBotOpen]);

  return (
    <>
      {isChatBotOpen && <OverlayDiv />}
      <ChatbotContainer />
    </>
  );
}
