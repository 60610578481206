import { AsurionDoodleSpinner } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';

const LoadingComponentContainer = styled.div`
  height: calc(100vh - 200px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingSpinner = () => (
  <LoadingComponentContainer>
    <AsurionDoodleSpinner />
  </LoadingComponentContainer>
);
