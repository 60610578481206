import { maskEmail } from '@3as-affiliates/shared/web/utils/ui-utils';
import { colors } from '@soluto-private/mx-asurion-ui-react';
import {
  ButtonSelectionCard,
  Icon,
} from '@soluto-private/mx-asurion-ui-react-v3';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: inline-block;
`;

export const Menu = styled.div<{ show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  right: 0;
  top: calc(100% + 7px);
  background: ${colors.white};
  min-width: 292px;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 1;
  padding: 1rem;
`;

export const MenuItem = styled.div<{ last?: boolean }>`
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  border-top: ${({ last }) => (last ? '1px solid' : 'none')};
  border-color: ${colors.neutralBright};
  color: ${colors.neutralDeeper};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  cursor: pointer;

  &:hover {
    background-color: ${colors.neutralBrightest};
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
`;

export const StyledButtonSelectionCard = styled(ButtonSelectionCard)`
  .chevron-toggle-up {
    rotate: 90deg;
  }
  .chevron-toggle-down {
    rotate: -90deg;
  }
`;

export type AvatarMenuProps = {
  label?: string;
  signOutLabel?: string;
  provideFeedbackLabel?: string;
  email: string;
  onProfileClick?: () => void;
  onProvideFeedbackClick?: () => void;
  onSignOut: () => void;
};

export const AvatarMenu = ({
  label,
  signOutLabel,
  provideFeedbackLabel,
  email,
  onProfileClick,
  onProvideFeedbackClick,
  onSignOut,
}: AvatarMenuProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleProfileClick = () => {
    setShowMenu(false);

    if (onProfileClick) {
      onProfileClick();
    }
  };

  const emailMasked = useMemo(() => maskEmail(email), [email]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const current = dropdownRef.current;

      if (current && !current.contains(event.target as Node)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef, showMenu]);

  return (
    <Container ref={dropdownRef}>
      <StyledButtonSelectionCard
        element="button"
        chevronClassName={
          showMenu ? 'chevron-toggle-down' : 'chevron-toggle-up'
        }
        iconSrc="RoundedUser"
        onClick={toggleMenu}
        style={{
          borderRadius: '9999px',
          backgroundColor: showMenu ? colors.neutralBrighter : colors.white,
          boxShadow: 'none',
        }}
      >
        {label || 'My Account'}
      </StyledButtonSelectionCard>
      <Menu show={showMenu}>
        <MenuItem onClick={handleProfileClick}>
          <Profile>
            <StyledIcon
              style={{ width: '42px', height: '42px' }}
              src={'RoundedUser'}
            ></StyledIcon>
            {emailMasked}
          </Profile>
        </MenuItem>
        {onProvideFeedbackClick && (
          <MenuItem onClick={onProvideFeedbackClick}>
            {provideFeedbackLabel || 'Provide Feedback'}
          </MenuItem>
        )}
        <MenuItem onClick={onSignOut} last={true}>
          {signOutLabel || 'Sign out'}
        </MenuItem>
      </Menu>
    </Container>
  );
};
