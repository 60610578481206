import { AuthUserProfile, personaConfig } from '../config';

export const isPersonaAvailable = (
  authUserInfo: AuthUserProfile,
  partner: string
): boolean => {
  const authUserPersonas = authUserInfo?.personas;
  if (authUserPersonas && authUserPersonas.length > 0) {
    const partnerPersonaConfig = personaConfig[partner];

    return authUserPersonas.some(
      ({ sourcePlatform, entityType, externalId }) =>
        sourcePlatform === partnerPersonaConfig.sourcePlatform &&
        entityType === partnerPersonaConfig.entityType &&
        externalId === partnerPersonaConfig.externalId
    );
  }
  return false;
};
