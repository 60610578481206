import { HttpStatusCodes } from '@3as-affiliates/shared/utils/fetch-http-client';

export enum ErrorActions {
  SIGN_UP = 'SIGN_UP',
  RETURN_TO_LOGIN = 'RETURN_TO_LOGIN',
}

export interface ResType {
  data?: object | Record<string, unknown>;
  error?: boolean;
  errorAction?: ErrorActions;
  errorMessage?: string;
  statusCode?: HttpStatusCodes;
}

export const genericSuccessResponse: ResType = {
  error: false,
};

export const userAlreadyExistsError: ResType = {
  error: true,
  errorMessage: 'User is already registered.',
  statusCode: HttpStatusCodes.Conflict,
};

export const userNotFoundError: ResType = {
  error: true,
  errorAction: ErrorActions.SIGN_UP,
  statusCode: HttpStatusCodes.NotFound,
};

export const genericServerError: ResType = {
  error: true,
  errorAction: ErrorActions.RETURN_TO_LOGIN,
  errorMessage: 'Something went wrong. Please try again later.',
};

export const somethingWentWrongError: ResType = {
  error: true,
  errorAction: ErrorActions.RETURN_TO_LOGIN,
  errorMessage: 'Something went wrong.',
};
