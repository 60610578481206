import { formatSpaceInUrl } from '@3as-affiliates/affiliates/web/utils/url-utils';
import { isMobileApp } from '../utils/mobileAppCheck';

export function shareLink(urlParam: string, message?: string) {
  const url = formatSpaceInUrl(urlParam);

  function shareLinkPayload(): string {
    return JSON.stringify({
      transactionId: crypto.randomUUID(),
      body: JSON.stringify({
        query: `
          mutation shareLink($url: String!, $message: String) { 
            shareLink(url: $url, message: $message) 
          }`,
        variables: {
          url,
          message,
        },
      }),
    });
  }

  if (isMobileApp()) {
    // eslint-disable-next-line
    // @ts-ignore-next-line
    window.ReactNativeWebView.postMessage(shareLinkPayload());
  } else {
    window.open(url, '_blank');
  }
}
