// this lib is added to take care of the type issue with fetch and body.
// this can be moved to a shared lib and used across all apps.
import {
  HttpClient,
  HttpClientGetOptions,
  HttpClientPostOptions,
  HttpClientResponse,
} from './http-client';

const convertBody = async (response: Response) => {
  const contentType = response.headers.get('Content-Type');

  if (!contentType) {
    return;
  } else if (/^application\/json/.test(contentType)) {
    return await response.json();
  } else {
    return await response.text();
  }
};

export const fetchHttpClient: HttpClient = {
  get: async (url: string, options?: HttpClientGetOptions) => {
    const response = await fetch(url, {
      ...options,
      method: 'GET',
      headers: options?.headers,
    });

    return {
      status: response.status,
      body: await convertBody(response),
    };
  },
  post: async <Request, Response>(
    url: string,
    options?: HttpClientPostOptions<Request>
  ): Promise<HttpClientResponse<Response>> => {
    const response = await fetch(url, {
      ...options,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...options?.headers,
      },
      body: JSON.stringify(options?.body),
    });
    return {
      status: response.status,
      body: (await convertBody(response)) as Response,
    };
  },
};
