import {
  getMobileAppInfo,
  isMobileApp,
  NativeAppFeature,
} from '@3as-affiliates/affiliates/web/native-app-libs';
import { useAppData } from '@3as-affiliates/shared/web/data-access/data-store';
import {
  MobileAppAuthResponseType,
  useAuth,
} from '@3as-affiliates/shared/web/utils/auth-provider';
import { useCallback, useEffect } from 'react';

export const NativeWebCommunicationListener: React.FC = () => {
  const { updateMobileAppAuthState } = useAuth();
  const { appInfo, updateAppInfo } = useAppData();
  if (!appInfo?.name || !appInfo?.version || !appInfo?.buildNumber) {
    getMobileAppInfo();
  }

  const loginHandler = useCallback(
    (data: MobileAppAuthResponseType) => {
      console.log('loginHandler', data);
      if (data?.error) {
        // ToDo: check and add error display for login error
        updateMobileAppAuthState(undefined);
      } else {
        updateMobileAppAuthState(data);
      }
    },
    [updateMobileAppAuthState]
  );

  const handleIncomingMessage = useCallback(
    (nativeEvent: MessageEvent) => {
      console.log('handleIncomingMessage', nativeEvent);
      try {
        const messageData = nativeEvent.data;
        if (!messageData || typeof messageData !== 'string') {
          console.error(
            'handleIncomingMessage: messageData is not a string',
            messageData
          );
          return;
        }
        const messageBodyData = JSON.parse(JSON.parse(messageData).body)?.data;
        const firstPropertyKey = Object.keys(messageBodyData)[0];
        const payload = messageBodyData[firstPropertyKey];
        let incomingData;
        if (typeof payload === 'string') {
          incomingData = JSON.parse(payload);
        } else {
          incomingData = payload;
        }
        if (
          incomingData?.feature === NativeAppFeature.NativeAppLogin ||
          incomingData?.feature === NativeAppFeature.NativeAppLoginRefresh
        ) {
          loginHandler(incomingData);
        } else if (firstPropertyKey === NativeAppFeature.MobileAppInfo) {
          updateAppInfo(incomingData);
        }
      } catch (error) {
        console.error('handleIncomingMessage error', error);
      }
    },
    [loginHandler, updateAppInfo]
  );

  useEffect(() => {
    if (isMobileApp()) {
      // @ts-expect-error The message comes to `document` rather than `window` on Android
      document.addEventListener('message', handleIncomingMessage);
      window.addEventListener('message', handleIncomingMessage);
    }
  }, [handleIncomingMessage]);

  return <div />;
};
