import { AnalyticsEventModel } from 'shisell';

export const APIWriter = async (
  eventModel: AnalyticsEventModel
): Promise<void> => {
  const eventIsoTime = new Date().toISOString();

  const edsApiUrl = process.env['NX_ANALYTICS_EDP_API_URL'] || '';
  const edsApiKey = process.env['NX_ANALYTICS_EDP_API_KEY'] || '';

  await fetch(edsApiUrl, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Api-Key': edsApiKey,
    },
    body: JSON.stringify({ ...eventModel, Time: eventIsoTime }),
  }).catch((error) =>
    console.warn('Could not write analytic to backend', {
      error,
      eventModel,
    })
  );
};
