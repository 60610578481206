import { AnalyticsExtras, useAnalyticsContext } from '../provider';

export const useAnalytics = () => {
  const { dispatcher, scope } = useAnalyticsContext();

  const dispatch = (
    actionType: 'Click' | 'View' | 'API Call Success' | 'API Call Failed',
    extras?: AnalyticsExtras
  ) => {
    dispatcher.dispatch(actionType, {
      ExtraData: extras,
      ...(scope ? { Scopes: [scope] } : {}),
    });
  };

  return { dispatch };
};
