import { Size } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';

export const PageContainer = styled.div`
  max-width: ${Size.XL}px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
`;
