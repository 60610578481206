import { useCallback } from 'react';

import type { AnalyticsExtras } from '../provider';
import { useAnalytics } from './useAnalytics';

export type ClickAnalyticsAttributes = {
  Cta: string;
  ActionId: string;
};

export const useClickAnalytics = () => {
  const { dispatch } = useAnalytics();
  const dispatchClickAnalytics = useCallback(
    (
      mandatoryAttributes: ClickAnalyticsAttributes,
      extras?: AnalyticsExtras
    ) => {
      dispatch('Click', { ...mandatoryAttributes, ...extras });
    },
    [dispatch]
  );

  return { dispatchClickAnalytics };
};
