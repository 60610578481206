import { colors } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';

const Disclaimer = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
  padding: 40px 32px;
  background-color: ${colors.black};
  color: ${colors.white};
`;

export const LegalDisclaimer = () => (
  <Disclaimer>
    The Asurion® and Asurion Appliance+™ trademarks are the property of Asurion,
    LLC. All rights reserved. All other trademarks are the property of their
    respective owners. Asurion is not affiliated with, sponsored by, or endorsed
    by any of the respective owners of the other trademarks appearing herein.
    <li>
      This plan will continue to renew each month unless canceled and will be
      billed to your payment card.
    </li>
    <li>
      You may cancel at any time for no additional charge. If canceled, a
      pro-rata refund of the service contract coverage may be provided as
      described in the Terms and Conditions.
    </li>
    <li>
      The plan begins on day 1 with access to appliance troubleshooting. All
      other benefits, including coverage for mechanical or electrical breakdowns
      on eligible products, begin on Day 31. Any preexisting conditions or
      breakdowns that occur before day 31 are not covered.
    </li>
    <li>
      The plan provides up to $5,000 in coverage every 12 months and up to the
      replacement value of the covered product per approved claim.
    </li>
    <li>
      Nonoriginal parts may be used for repair, and a replacement product may be
      a new, refurbished, or remanufactured product.
    </li>
    <li>
      A service fee of $99 plus tax will be charged for each approved claim.
    </li>
  </Disclaimer>
);
