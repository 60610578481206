import { colors, Text } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { AppsDownloadButtons } from './AppsDownloadButtons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 16px 0px;
  background-color: ${colors.neutralBrightest};
`;

export const DownloadAppCalloutWithTitle = ({
  pageName,
  showCurrentPlatformAppButtonOnly = false,
}: {
  pageName: string;
  showCurrentPlatformAppButtonOnly?: boolean;
}) => {
  return (
    <Container>
      <Text size={4} weight={'feather'} style={{ textAlign: 'center' }}>
        Download the app for faster updates
      </Text>
      <AppsDownloadButtons
        pageName={pageName}
        showCurrentPlatformAppButtonOnly={showCurrentPlatformAppButtonOnly}
      />
    </Container>
  );
};
