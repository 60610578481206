import {
  DownloadAppCalloutWithTitle,
  FooterWithDeleteAccount as Footer,
  ProgramInfoSection,
  SignedInBottomStickySection,
  WelcomeSection,
} from '@3as-affiliates/affiliates/web/components';
import { PageNames } from '@3as-affiliates/affiliates/web/config';
import { InstallMobileAppModal } from '@3as-affiliates/affiliates/web/features/app-install-modals';
import {
  ChatBot,
  FirstTimeChatOverview,
} from '@3as-affiliates/affiliates/web/features/chat-bot';
import { showMobileAppInstallPrompts } from '@3as-affiliates/affiliates/web/utils/user-device-info';
import {
  Header,
  PageContainerWithViewAnalytics,
} from '@3as-affiliates/shared/web/components';
import styled from 'styled-components';

const HomePageContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  margin: 0;
`;

export const HomePage = () => (
  <PageContainerWithViewAnalytics pageName={PageNames.HomePage}>
    <HomePageContainer>
      <Header pageName={PageNames.HomePage} logoName="Affiliates" />
      <WelcomeSection />
      <ProgramInfoSection />
      {showMobileAppInstallPrompts && (
        <DownloadAppCalloutWithTitle
          pageName={PageNames.HomePage}
          showCurrentPlatformAppButtonOnly={true}
        />
      )}
      <Footer />
      <SignedInBottomStickySection pageName={PageNames.HomePage} />
      <FirstTimeChatOverview />
      <ChatBot />
    </HomePageContainer>
    <InstallMobileAppModal />
  </PageContainerWithViewAnalytics>
);
