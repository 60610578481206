import {
  CommonComponents,
  CommonSections,
} from '@3as-affiliates/affiliates/web/config';
import { CHAT_ROLES } from '@3as-affiliates/shared/types-configs';
import { useClickAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import { colors, Skeleton } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { useChatBotContext } from '../../provider';
import { Message } from '../../types-configs';

const ConversationInnerWindowWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  margin-top: auto;
  margin-left: -16px;
  margin-right: -16px;
  overflow-y: auto;
`;

const ConversationListWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  place-content: flex-end;
  padding: 16px;
`;

interface ContentBubbleProps {
  role: string;
  prevRole?: string;
}

const ContentBubbleContainer = styled.div<ContentBubbleProps>`
  border-radius: 20px;
  padding: 8px 20px;
  width: fit-content;
  margin-top: ${(props) => (props.prevRole === props.role ? '16px' : '24px')};
`;

const ContentBubble = styled(ContentBubbleContainer)<ContentBubbleProps>`
  align-self: ${(props) =>
    props.role === CHAT_ROLES.ASSISTANT ? 'flex-start' : 'flex-end'};
  background: ${(props) =>
    props.role === CHAT_ROLES.ASSISTANT
      ? `${colors.neutralBrighter}`
      : `${colors.neutralDeepest}`};
  color: ${(props) =>
    props.role === CHAT_ROLES.ASSISTANT ? 'black' : 'white'};
`;

const CannedContentBubble = styled(ContentBubbleContainer)`
  align-self: flex-end;
  color: ${colors.neutralDeeper};
  border: 1px dashed ${colors.neutralDeeper};
  cursor: pointer;
`;

const SkeletonWrapper = styled(Skeleton)`
  margin-top: 16px;
  width: 20rem;
`;

export function ConversationInnerWindow() {
  const {
    conversationList,
    cannedMessagesList,
    waitingForUserAnswer,
    getAnswerForUserQuestion,
    chatSessionId,
  } = useChatBotContext();
  const { dispatchClickAnalytics } = useClickAnalytics();

  const handleCannedMessageClick = (conversation: Message) => {
    dispatchClickAnalytics(
      {
        Cta: 'NewCannedMessageSubmitted',
        ActionId: 'NewCannedMessageSubmitted',
      },
      {
        Component: CommonComponents.ChatBot,
        Section: CommonSections.ChatBotConversationMainWindow,
        ChatSessionId: chatSessionId,
      }
    );

    getAnswerForUserQuestion(conversation.content);
  };

  return (
    <ConversationInnerWindowWrapper>
      <ConversationListWrapper>
        {conversationList.map((conversation, index) => (
          <ContentBubble
            key={index}
            className="fs-mask"
            role={conversation.role}
            prevRole={index === 0 ? '' : conversationList[index - 1].role}
          >
            {conversation.content}
          </ContentBubble>
        ))}
        {cannedMessagesList.map((conversation, index) => (
          <CannedContentBubble
            key={index}
            className="fs-mask"
            role={conversation.role}
            onClick={() => handleCannedMessageClick(conversation)}
          >
            {conversation.content}
          </CannedContentBubble>
        ))}
        {waitingForUserAnswer && <SkeletonWrapper height="2.25rem" />}
      </ConversationListWrapper>
    </ConversationInnerWindowWrapper>
  );
}
