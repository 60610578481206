import { openLink } from '@3as-affiliates/affiliates/web/native-app-libs';
import { useClickAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import {
  ButtonSelectionCard,
  colors,
  Text,
} from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import WarningIcon from './assets/warning.svg';

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Icon = styled.img`
  height: 20px;
  width: 20px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

const Title = styled(Text)`
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20.8px !important;
`;

const Subtitle = styled(Text)`
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18.2px !important;
  color: ${colors.neutralDeepest};
`;

interface Props {
  pageName: string;
}

export const ServicerSignupCTA = ({ pageName }: Props) => {
  const { dispatchClickAnalytics } = useClickAnalytics();

  const onServicerCtaClick = () => {
    dispatchClickAnalytics(
      {
        Cta: 'Servicer Signup CTA',
        ActionId: 'ServicerSignupCTA',
      },
      {
        PageName: pageName,
      }
    );
    openLink('https://qndv9kblivb.typeform.com/ASNApplication');
  };

  return (
    <ButtonSelectionCard onClick={onServicerCtaClick}>
      <Container>
        <Icon src={WarningIcon} alt="Sign up as a servicer" />
        <TextContainer>
          <Title size={5} color={colors.black}>
            Want more jobs in your area?
          </Title>
          <Subtitle>
            Join the Asurion network for priority on network jobs and your local
            Appliance+ customers.
          </Subtitle>
        </TextContainer>
      </Container>
    </ButtonSelectionCard>
  );
};
