import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  text-align: center;
`;

const Icon = styled.img`
  height: 52px;
  width: 52px;
  margin: auto;
`;

const IconText = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 130%;
  width: 165px;
  padding-top: 22px;
  margin: 0px;
`;

interface Props {
  iconSrc: string;
  iconAlt: string;
  text: string;
}

export const ValuePropItem = ({ iconSrc, iconAlt, text }: Props) => (
  <Container>
    <Icon src={iconSrc} alt={iconAlt} />
    <IconText>{text}</IconText>
  </Container>
);
