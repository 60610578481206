import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Apercu Pro';
    src: url('../assets/fonts/Apercu-Light-Pro.woff2') format('woff2');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Apercu Pro';
    src: url('../assets/fonts/Apercu-Regular-Pro.woff2') format('woff2');
    font-weight: normal;
  }

  @font-face {
    font-family: 'Apercu Pro';
    src: url('../assets/fonts/Apercu-Bold-Pro.woff2') format('woff2');
    font-weight: 700;
  }

  :root {
    --primary-font-family: Apercu Pro, sans-serif;
    --secondary-font-family: Apercu Pro, sans-serif;
  }

  html {
    font-family: var(--primary-font-family);
    font-weight: 400;
    line-height: 1.5;
    font-size: 16px;
    font-style: normal;
    -webkit-tap-highlight-color: transparent;
  }

  .chat-no-scroll {
    overflow: hidden !important;
  }
`;

export default GlobalStyle;
