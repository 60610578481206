import { CHAT_ROLES } from '@3as-affiliates/shared/types-configs';
import { ResType } from '@3as-affiliates/shared/web/data-access/api-client';
import { ReactNode } from 'react';

export type Message = {
  role: CHAT_ROLES;
  content: string;
  contentType?: 'default' | 'error' | 'defaultWelcomeMessage' | 'cannedMessage';
};

export type ConversationList = Array<Message>;

export interface ChatBotContextValueProps {
  chatError: ResType;
  chatSessionId: string;
  conversationList: ConversationList;
  firstTimeUser: boolean;
  updateFirstTimeUser: () => void;
  isChatBotOpen: boolean;
  openChatBot: () => void;
  closeChatBot: () => void;
  getAnswerForUserQuestion: (question: string) => void;
  waitingForUserAnswer: boolean;
  cannedMessagesList: ConversationList;
}

export interface ChatBotProviderProps {
  children: ReactNode;
}

export const chatBotFirstTimeUserLocalStorageKey = 'chatBotFirstTimeUserKey';

type ChatMessages = {
  [key: string]: Message;
};

export const defaultWelcomeMessages: ChatMessages = {
  welcomeMessage1: {
    role: CHAT_ROLES.ASSISTANT,
    content: 'Hello',
    contentType: 'defaultWelcomeMessage',
  },
  welcomeMessage2: {
    role: CHAT_ROLES.ASSISTANT,
    content: 'What can I help you with today?',
    contentType: 'defaultWelcomeMessage',
  },
};

export const cannedMessages: ChatMessages = {
  cannedMessage1: {
    role: CHAT_ROLES.HUMAN,
    content: 'What does Appliance+ cover?',
    contentType: 'cannedMessage',
  },
  cannedMessage2: {
    role: CHAT_ROLES.HUMAN,
    content: 'How much does Appliance+ cost?',
    contentType: 'cannedMessage',
  },
  cannedMessage3: {
    role: CHAT_ROLES.HUMAN,
    content: 'What are the benefits of Appliance+?',
    contentType: 'cannedMessage',
  },
};
