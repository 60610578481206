import { breakpoints, colors, Text } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import CheckMark from './assets/check-mark.svg';

const ListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: left;
  align-self: flex-start;

  ${breakpoints.md} {
    align-self: center;
  }

  padding-bottom: 16px;
  margin: 0px;
  text-decoration: none;
  padding-inline-start: 0px;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 24px;
  padding-bottom: 8px;
`;

const ItemText = styled(Text)`
  font-weight: 300 !important;
  align-self: stretch;
`;

export const CheckList = () => {
  const items = ['No receipts necessary', 'Non-contractual', 'Hassle-free'];
  return (
    <ListWrapper>
      {items.map((item) => (
        <ListItem key={item}>
          <img src={CheckMark} alt="check mark" />
          <ItemText size={2} color={colors.black}>
            {item}
          </ItemText>
        </ListItem>
      ))}
    </ListWrapper>
  );
};
