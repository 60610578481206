import { breakpoints } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { SectionSubTitle } from '../section-sub-title';
import { SectionTitle } from '../section-title';
import CustomerIcon from './assets/customer.svg';
import IncomeIcon from './assets/income.svg';
import NoReceiptIcon from './assets/no-receipt-icon.svg';
import RepairIcon from './assets/repair.svg';
import { ValuePropItem } from './components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #f0f0f5 0%, rgba(255, 255, 255, 0) 100%),
    #fff;
`;

const ValuePropsItemsContainer = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-content: center;
  ${breakpoints.md} {
    grid-template-columns: repeat(auto-fill, 180px);
  }
  margin: 16px 0px;
`;

const ValuePropItems = [
  {
    icon: IncomeIcon,
    text: 'Earn additional income weekly',
  },
  {
    icon: NoReceiptIcon,
    text: 'No purchase needed to be an affiliate',
  },
  {
    icon: CustomerIcon,
    text: 'Create long term value for your customers',
  },
  {
    icon: RepairIcon,
    text: 'Add more repair jobs to your pipeline',
  },
];

const renderValuePropItems = () =>
  ValuePropItems.map((item, index) => (
    <ValuePropItem
      iconSrc={item.icon}
      iconAlt={item.text}
      text={item.text}
      key={`valuePropitem${index}`}
    />
  ));

export const MoreFromService = () => (
  <Container>
    <SectionTitle title="Get more from the services you offer" />
    <SectionSubTitle
      subTitle="Make more money, grow your business, and take your service to the next
      level by offering your customers Appliance+."
    />
    <ValuePropsItemsContainer>
      {renderValuePropItems()}
    </ValuePropsItemsContainer>
  </Container>
);
