import { Modal } from '@soluto-private/aui-react-modal';
import { Text } from '@soluto-private/mx-asurion-ui-react';
import { useEffect, useState } from 'react';

interface NotificationModalProps {
  title: string;
  content: string;
  actionComponent?: React.ReactElement;
  isOpen?: boolean;
  closeModal: () => void;
  delayToShowModal?: number;
  modalAriaLabel?: string;
  modalStyle?: React.CSSProperties;
}
// ToDo: update delayToShowModal without default value of 2000
export function NotificationModal({
  title,
  content,
  actionComponent,
  isOpen = false,
  closeModal,
  delayToShowModal = 2000,
  modalAriaLabel = 'Notification modal',
  modalStyle,
}: NotificationModalProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setIsModalOpen(true);
      }, delayToShowModal);
      return () => clearTimeout(timer);
    } else {
      setIsModalOpen(false);
    }
  }, [delayToShowModal, isOpen]);

  const onCloseModal = () => {
    setIsModalOpen(false);
    closeModal();
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onCloseModal={onCloseModal}
      ariaLabel={modalAriaLabel}
      style={modalStyle}
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Content>
        <Text>{content}</Text>
      </Modal.Content>
      {actionComponent && <Modal.Footer>{actionComponent}</Modal.Footer>}
    </Modal>
  );
}
