import { MsaLabelWithLink } from '@3as-affiliates/affiliates/web/components';
import {
  AppliancesTechnicianAffiliatesRoles,
  PageNames,
  statesList,
} from '@3as-affiliates/affiliates/web/config';
import {
  AffiliateTypeformKeys,
  getAffiliateQueryParams,
} from '@3as-affiliates/affiliates/web/utils/url-utils';
import {
  Footer,
  Header,
  PageContainerWithViewAnalytics,
} from '@3as-affiliates/shared/web/components';
import {
  ResType,
  useCreateAffiliate,
} from '@3as-affiliates/shared/web/data-access/api-client';
import {
  Button,
  colors,
  Dropdown,
  Text,
  TextField,
} from '@soluto-private/mx-asurion-ui-react';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { AsYouType } from 'libphonenumber-js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { boolean, object, string } from 'yup';
import 'yup-phone-lite';
import { Routes } from '../router';
import { SignupAlertBanner } from './SignupAlertBanner';

const MainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0px auto;
  width: 100%;
`;

const Title = styled.h1`
  font-size: 20px;
  font-weight: 300;
  line-height: 130%;
  padding-left: 16px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 16px 48px 16px;
`;

const NameContainer = styled.div`
  display: flex;
  gap: 12px;
  div {
    width: 100%;
  }
`;

const TermsInput = styled.input`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

const TermsLabel = styled.label`
  display: flex;
  align-items: center;
  line-height: 130%;
`;

const TermsErrorText = styled.text`
  margin-top: -8px;
  padding-left: 32px;
  font-size: 0.875rem;
  color: ${colors.errorDeep};
`;

interface SignupFormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  state: string;
  companyName: string;
  role: string;
  msaCheck: boolean;
}

const signupFormValidationSchema = object({
  firstName: string().required('Please enter a first name'),
  lastName: string().required('Please enter a last name'),
  phoneNumber: string()
    .phone('US', 'Please enter a valid phone number')
    .required('Please enter a valid 10-digit phone number'),
  state: string().required('State is required.'),
  companyName: string().required('Please enter a company name'),
  role: string()
    .oneOf(['owner', 'technician'], 'Please select an option')
    .required('Please select your role'),
  msaCheck: boolean()
    .oneOf([true], 'You must agree to the Master Services Agreement')
    .required('You must agree to the Master Services Agreement'),
});

const savedAffiliateInfoFromUrl = getAffiliateQueryParams() || {};

const signupFormDefaultValues: SignupFormValues = {
  firstName: savedAffiliateInfoFromUrl[AffiliateTypeformKeys.FirstName] ?? '',
  lastName: savedAffiliateInfoFromUrl[AffiliateTypeformKeys.LastName] ?? '',
  phoneNumber: new AsYouType('US').input(
    savedAffiliateInfoFromUrl[AffiliateTypeformKeys.PhoneNumber] ?? ''
  ),
  state: savedAffiliateInfoFromUrl[AffiliateTypeformKeys.State] ?? '',
  companyName:
    savedAffiliateInfoFromUrl[AffiliateTypeformKeys.CompanyName] ?? '',
  role: savedAffiliateInfoFromUrl[AffiliateTypeformKeys.Role] ?? '',
  msaCheck: false,
};

export const SignupPage = () => {
  const navigate = useNavigate();
  const { signupAffiliate } = useCreateAffiliate();
  const [signupRes, setSignupRes] = useState<ResType>();

  const onSignupFormSubmit = async (values: SignupFormValues) => {
    setSignupRes(undefined);
    const signUpRes = await signupAffiliate(values);
    if (!signUpRes.error) {
      navigate(Routes.LoggedInHome, { replace: true });
    } else {
      setSignupRes(signUpRes);
    }
  };

  const {
    errors,
    touched,
    isValid,
    validateForm,
    handleBlur,
    handleChange,
    values,
    handleSubmit,
  } = useFormik<SignupFormValues>({
    initialValues: signupFormDefaultValues,
    validationSchema: signupFormValidationSchema,
    onSubmit: onSignupFormSubmit,
  });

  const formikTouched: FormikTouched<{ [field: string]: unknown }> = touched;
  const formikErrors: FormikErrors<{ [field: string]: unknown }> = errors;

  function getFieldStatus(fieldName: string) {
    return formikTouched[fieldName] && formikErrors[fieldName]
      ? 'error'
      : 'default';
  }

  function getHelperText(fieldName: string, helperText?: string) {
    const text =
      formikTouched[fieldName] && formikErrors[fieldName]
        ? formikErrors[fieldName]
        : helperText;
    return text ? text : Array.isArray(text) ? text[0] : '';
  }

  useEffect(() => {
    validateForm && validateForm();
  }, [validateForm]);

  return (
    <PageContainerWithViewAnalytics pageName={PageNames.SignUpPage}>
      {signupRes?.error && <SignupAlertBanner error={signupRes} />}
      <Header pageName={PageNames.SignUpPage} logoName="Affiliates" />
      <MainContentContainer>
        <Title>Let's start with the basics</Title>
        <StyledForm onSubmit={handleSubmit}>
          <NameContainer>
            <TextField
              data-testid="affiliate-signup-first-name"
              label={'First name'}
              name="firstName"
              className="fs-mask"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              fieldStatus={getFieldStatus('firstName')}
              helperText={getHelperText('firstName', '')}
            />
            <TextField
              data-testid="affiliate-signup-last-name"
              label={'Last name'}
              name="lastName"
              className="fs-mask"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              fieldStatus={getFieldStatus('firstName')}
              helperText={getHelperText('firstName', '')}
            />
          </NameContainer>
          <TextField
            data-testid="affiliate-signup-phone-number"
            label={'Phone number'}
            name="phoneNumber"
            className="fs-mask"
            value={values.phoneNumber}
            onChange={(event) => {
              const formatted = new AsYouType('US').input(event.target.value);
              event.target.value = formatted;
              handleChange(event);
            }}
            onBlur={handleBlur}
            fieldStatus={getFieldStatus('phoneNumber')}
            helperText={getHelperText('phoneNumber')}
          />
          <Dropdown
            data-testid="affiliate-signup-state"
            label={'State of residence'}
            name="state"
            options={statesList}
            value={values.state}
            onChange={handleChange}
            onBlur={handleBlur}
            fieldStatus={getFieldStatus('state')}
            helperText={getHelperText('state')}
          />
          <TextField
            data-testid="affiliate-signup-company-name"
            label={'Company name'}
            name="companyName"
            className="fs-mask"
            value={values.companyName}
            onChange={handleChange}
            onBlur={handleBlur}
            fieldStatus={getFieldStatus('companyName')}
            helperText={getHelperText('companyName', '')}
          />
          <Dropdown
            data-testid="affiliate-signup-owner-role"
            label={'Owner/Technician'}
            name="role"
            options={AppliancesTechnicianAffiliatesRoles}
            value={values.role}
            onChange={handleChange}
            onBlur={handleBlur}
            fieldStatus={getFieldStatus('role')}
            helperText={getHelperText('role')}
          />
          <TermsLabel>
            <TermsInput
              data-testid="affiliate-signup-msa"
              type={'checkbox'}
              name="msaCheck"
              checked={values.msaCheck}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Text>
              <MsaLabelWithLink pageName={PageNames.SignUpPage} />
            </Text>
          </TermsLabel>
          {touched['msaCheck'] && errors['msaCheck'] && (
            <TermsErrorText>{errors['msaCheck']}</TermsErrorText>
          )}
          <Button
            data-testid="affiliate-signup-submit"
            variant="default"
            type="submit"
            size="medium"
            title={'Submit'}
            disabled={!isValid}
          >
            Submit
          </Button>
        </StyledForm>
      </MainContentContainer>
      <Footer />
    </PageContainerWithViewAnalytics>
  );
};
