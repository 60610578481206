import { isAndroidDevice } from '@3as-affiliates/affiliates/web/utils/user-device-info';

export enum PageNames {
  HomePage = 'homePage',
  AuthRedirectPage = 'authRedirectPage',
  LandingPage = 'landingPage',
  LoadingPage = 'loadingPage',
  MSAPage = 'msaPage',
  SignUpPage = 'signUpPage',
}

export enum CommonComponents {
  ChatBot = 'ChatBot',
}

export enum CommonSections {
  ChatBotFirstTimeOverview = 'FirstTimeOverview',
  ChatBotConversationMainWindow = 'ConversationMainWindow',
}

export const AppliancesTechnicianAffiliatesRoles = [
  {
    name: 'Owner',
    value: 'owner',
  },
  {
    name: 'Technician',
    value: 'technician',
  },
];

export const affiliatesAndroidAppUrl =
  'https://play.google.com/store/apps/details?id=com.asurion.affiliates&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';

export const affiliatesIosAppUrl =
  'https://apps.apple.com/us/app/asurion-affiliates/id6470670833';

export const currentOsAppUrl = isAndroidDevice()
  ? affiliatesAndroidAppUrl
  : affiliatesIosAppUrl;
