import React, { createContext, useContext, useState } from 'react';
import {
  AppDataContextProps,
  AppDataProviderProps,
  AppInfoType,
} from './config';

const AppDataContext = createContext<AppDataContextProps | undefined>(
  undefined
);

export const useAppData = () => {
  const context = useContext(AppDataContext);
  if (!context) throw new Error('useAppData context not initialized');
  return context;
};

export const AppDataProvider: React.FC<AppDataProviderProps> = ({
  children,
}) => {
  const [appInfo, setAppInfo] = useState<AppInfoType | null>(null);

  const updateAppInfo = (newData: AppInfoType) => {
    setAppInfo({ ...appInfo, ...newData });
  };

  const [affiliateInfo, setAffiliateInfo] = useState<Record<
    string,
    unknown
  > | null>(null);

  const updateAffiliateInfo = (newData: Record<string, unknown>) =>
    setAffiliateInfo({ ...affiliateInfo, ...newData });

  return (
    <AppDataContext.Provider
      value={{ appInfo, affiliateInfo, updateAppInfo, updateAffiliateInfo }}
    >
      {children}
    </AppDataContext.Provider>
  );
};
