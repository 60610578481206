import {
  Footer,
  Header,
  LoadingSpinner,
  PageContainer,
} from '@3as-affiliates/shared/web/components';

type Props = {
  pageName: string;
};

export const LoadingSpinnerPage = ({ pageName }: Props) => (
  <PageContainer>
    <Header pageName={pageName} />
    <LoadingSpinner />
    <Footer />
  </PageContainer>
);
