import styled from 'styled-components';

const Title = styled.h1`
  font-size: 32px;
  font-weight: 300;
  line-height: 120%;
  padding: 24px 16px 0px 16px;
  margin: 0px;
`;

interface Props {
  title: string;
}

export const SectionTitle = ({ title }: Props) => <Title>{title}</Title>;
