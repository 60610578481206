import {
  CommonComponents,
  CommonSections,
} from '@3as-affiliates/affiliates/web/config';
import { ComponentTypes } from '@3as-affiliates/shared/types-configs';
import {
  useClickAnalytics,
  useViewAnalytics,
} from '@3as-affiliates/shared/web/utils/analytics';
import { Button, Drawer } from '@soluto-private/mx-asurion-ui-react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useChatBotContext } from '../../provider';
import BannerImageIcon from './assets/overview-banner.svg';

const Container = styled.div`
  background: white;
  height: 465px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
`;

const BannerImage = styled.img`
  max-width: 343px;
  min-width: 200px;
`;

const OverviewItems = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  padding: 8px 0;
`;

const StartChatButton = styled(Button)`
  margin: 12px 0px;
`;

export function FirstTimeChatOverview() {
  const { firstTimeUser, updateFirstTimeUser, openChatBot } =
    useChatBotContext();

  const [isChatOverviewOpen, setIsChatOverviewOpen] = useState(false);

  const { dispatchClickAnalytics } = useClickAnalytics();
  const { elementRef: chatElementRef } = useViewAnalytics(
    {
      ComponentType: ComponentTypes.Component,
      ComponentId: CommonComponents.ChatBot,
    },
    { SectionId: CommonSections.ChatBotFirstTimeOverview }
  );

  useEffect(() => {
    if (firstTimeUser) {
      setTimeout(() => setIsChatOverviewOpen(true), 2000);
    }
  }, [firstTimeUser]);

  const closeChatOverview = () => {
    updateFirstTimeUser();
    setIsChatOverviewOpen(false);
  };

  const handleStartChatClick = () => {
    dispatchClickAnalytics(
      {
        Cta: 'Start chat',
        ActionId: 'startChatButton',
      },
      {
        ComponentType: 'Component',
        ComponentId: CommonComponents.ChatBot,
        SectionId: CommonSections.ChatBotFirstTimeOverview,
      }
    );
    openChatBot();
    closeChatOverview();
  };

  const handleClosedState = (state: boolean) => {
    dispatchClickAnalytics(
      {
        Cta: 'ChatBotClose',
        ActionId: 'ChatBotCloseButton',
      },
      {
        Component: CommonComponents.ChatBot,
        Section: CommonSections.ChatBotFirstTimeOverview,
      }
    );
    if (!state) {
      closeChatOverview();
    }
  };

  return (
    <Drawer open={isChatOverviewOpen} onOpenUpdate={handleClosedState}>
      <Container ref={chatElementRef}>
        <Title>Ask Freeon</Title>
        <BannerImage src={BannerImageIcon} />
        <OverviewItems>
          I'm here to answer all of your Appliance+ questions. I can help answer
          questions like:
        </OverviewItems>
        <OverviewItems>* "What does Appliance+ cover?"</OverviewItems>
        <OverviewItems>* "How much does it cost?"</OverviewItems>
        <OverviewItems>* "How should I explain the benefits?"</OverviewItems>
        <StartChatButton
          size="medium"
          variant="default"
          onClick={handleStartChatClick}
        >
          Start chat
        </StartChatButton>
      </Container>
    </Drawer>
  );
}
