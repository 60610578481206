export const maskEmail = (email: string): string => {
  if (!email) {
    return '';
  }

  if (!email.includes('@')) {
    return email;
  }

  const [first, last] = email.toLowerCase().split('@');

  if (first?.length > 2) {
    const replaced = '*'.repeat(Math.min(first.length - 2, 5));
    const maskedPart = first[0][0] + replaced + first[first.length - 1];

    return maskedPart + '@' + last;
  }

  return email;
};
