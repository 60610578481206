import { isMobileApp } from '../utils/mobileAppCheck';

export function getMobileAppInfo() {
  function getAppInfo(): string {
    return JSON.stringify({
      transactionId: crypto.randomUUID(),
      body: JSON.stringify({
        query: `
          query app { 
            app {
              name
              version
              buildNumber
            }
          }`,
      }),
    });
  }

  if (isMobileApp()) {
    // eslint-disable-next-line
    // @ts-ignore-next-line
    window.ReactNativeWebView.postMessage(getAppInfo());
  }
}
