import {
  ChatContextMessages,
  ChatMessage,
} from '@3as-affiliates/shared/types-configs';
import { HttpStatusCodes } from '@3as-affiliates/shared/utils/fetch-http-client';
import { useAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import { useAuth } from '@3as-affiliates/shared/web/utils/auth-provider';
import { useChatApi } from '../api-gateway';
import {
  genericServerError,
  ResType,
  somethingWentWrongError,
} from '../types-configs/standardizeDataResponse';

export const useGetChatAnswer = () => {
  const { getChatAnswer } = useChatApi();
  const { dispatch } = useAnalytics();

  const { getAuthUserInfo } = useAuth();

  const getUserChatAnswer = async (
    conversationContext: ChatContextMessages,
    sessionId: string
  ): Promise<ResType> => {
    const { asurionId } = await getAuthUserInfo();

    if (!asurionId) {
      return somethingWentWrongError;
    }

    const chatBotRequest = {
      asurionId,
      sessionId,
      messages: conversationContext,
    };

    try {
      const chatAnswerRes = await getChatAnswer(chatBotRequest);

      dispatch('API Call Success', { Api: 'chat api: get chat answer' });

      if (chatAnswerRes.status !== HttpStatusCodes.OK) {
        return { statusCode: chatAnswerRes.status, ...genericServerError };
      }

      const responseMessage: ChatMessage = JSON.parse(
        chatAnswerRes.body as string
      );

      return {
        error: false,
        data: responseMessage,
      };
    } catch (error) {
      dispatch('API Call Failed', { Api: 'chat api: get chat answer' });
      return genericServerError;
    }
  };

  return { getUserChatAnswer };
};
