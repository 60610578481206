import {
  DownloadAppWithDeviceImage,
  LoginBanner,
  MoreFromService,
  PageTopBanner,
  SalesSuccess,
  TrustSection,
} from '@3as-affiliates/affiliates/web/components';
import { PageNames } from '@3as-affiliates/affiliates/web/config';
import { showMobileAppInstallPrompts } from '@3as-affiliates/affiliates/web/utils/user-device-info';
import {
  Footer,
  Header,
  PageContainerWithViewAnalytics,
} from '@3as-affiliates/shared/web/components';
import { useClickAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import {
  AuthenticationState,
  useAuth,
} from '@3as-affiliates/shared/web/utils/auth-provider';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../router';

export const LandingPage = () => {
  const { logIn, authState } = useAuth();
  const { dispatchClickAnalytics } = useClickAnalytics();
  const navigate = useNavigate();

  // Handling unregistered wild card browser URL
  useEffect(() => {
    if (authState === AuthenticationState.LoggedIn) {
      navigate(Routes.LoggedInHome, { replace: true });
    }
  }, [navigate, authState]);

  const onLoginButtonClick = () => {
    dispatchClickAnalytics(
      {
        Cta: 'Sign in',
        ActionId: 'signInButton',
      },
      {
        PageName: PageNames.LandingPage,
      }
    );
    logIn();
  };

  return (
    <PageContainerWithViewAnalytics pageName={PageNames.LandingPage}>
      <Header pageName={PageNames.LandingPage} logoName="Affiliates" />
      <PageTopBanner />
      <LoginBanner
        onLoginButtonClick={onLoginButtonClick}
        pageName={PageNames.LandingPage}
      />
      {showMobileAppInstallPrompts ? <SalesSuccess /> : <MoreFromService />}
      <TrustSection />
      {showMobileAppInstallPrompts && (
        <DownloadAppWithDeviceImage pageName={PageNames.LandingPage} />
      )}
      <Footer />
    </PageContainerWithViewAnalytics>
  );
};
