import { AffiliateType } from '@3as-affiliates/shared/types-configs';
import { fetchHttpClient } from '@3as-affiliates/shared/utils/fetch-http-client';
import { useApiCommons } from '../utils/useApiCommons';

const baseUrl = process.env['NX_AFFILIATE_SERVICES_BASE_URL'];

export const useAffiliateApi = () => {
  const { getCommonHeaders } = useApiCommons();

  const getAffiliateInfo = async (asurionId: string) => {
    const headers = await getCommonHeaders();
    const response = await fetchHttpClient.get(
      `${baseUrl}/affiliates/${asurionId}`,
      {
        headers,
      }
    );
    return response;
  };

  const createAffiliate = async (affiliate: AffiliateType) => {
    const headers = await getCommonHeaders();

    const response = await fetchHttpClient.post(`${baseUrl}/affiliates`, {
      body: affiliate,
      headers,
    });
    return response;
  };

  return { getAffiliateInfo, createAffiliate };
};
