import { CommonComponents } from '@3as-affiliates/affiliates/web/config';
import { useClickAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import { BetaContext, Icon } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { useChatBotContext } from '../provider';
import { ConversationMainWindow } from './conversation-main-window';

const ChatbotContainerWrapper = styled.div<{ open: boolean }>`
  position: fixed;
  bottom: 0px;
  left: 0;
  z-index: 1000;
  width: calc(100% - 32px);
  transition: all 0.3s ease-in-out;
  transform: ${(props) => (props.open ? 'translateY(0)' : 'translateY(100%)')};
  background-color: white;
  padding: 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0px;
  padding: 16px;
  cursor: pointer;
  border-radius: 50%;
  background-color: white;
`;

export function ChatbotContainer() {
  const { dispatchClickAnalytics } = useClickAnalytics();
  const { isChatBotOpen, closeChatBot, chatSessionId } = useChatBotContext();

  const handleChatBotClose = (state: boolean) => {
    dispatchClickAnalytics(
      {
        Cta: 'ChatBotClose',
        ActionId: 'ChatBotCloseButton',
      },
      {
        Component: CommonComponents.ChatBot,
        ChatSessionId: chatSessionId,
      }
    );
    !state && closeChatBot();
  };

  return (
    <ChatbotContainerWrapper open={isChatBotOpen} className="chatbot-container">
      <CloseButton>
        <BetaContext.Provider value={{ useIconBeta: true }}>
          <Icon
            src="closeX"
            size="large"
            onClick={() => handleChatBotClose(false)}
          />
        </BetaContext.Provider>
      </CloseButton>
      <ConversationMainWindow />
    </ChatbotContainerWrapper>
  );
}
