import { HttpStatusCodes } from './HttpStatusCodes';

export type HttpErrorOptions = {
  expose?: boolean;
};

export class HttpError extends Error {
  private expose: boolean;

  constructor(
    public readonly statusCode: number,
    public override readonly message: string,
    options?: HttpErrorOptions
  ) {
    super(message);

    this.expose = options?.expose || false;
  }
}

type CreateErrorParams = {
  message?: string;
  options?: HttpErrorOptions;
};

export const createBadRequestError = ({
  message = HttpStatusCodes[HttpStatusCodes.BadRequest],
  options,
}: CreateErrorParams): HttpError =>
  new HttpError(HttpStatusCodes.BadRequest, message, options);

export const createNotImplementedError = ({
  message = HttpStatusCodes[HttpStatusCodes.NotImplemented],
  options,
}: CreateErrorParams): HttpError =>
  new HttpError(HttpStatusCodes.NotImplemented, message, options);

export const createUnauthorizedError = ({
  message = HttpStatusCodes[HttpStatusCodes.Unauthorized],
  options,
}: CreateErrorParams): HttpError =>
  new HttpError(HttpStatusCodes.Unauthorized, message, options);

export const createInternalServerError = ({
  message = HttpStatusCodes[HttpStatusCodes.InternalServerError],
  options,
}: CreateErrorParams): HttpError =>
  new HttpError(HttpStatusCodes.InternalServerError, message, options);
