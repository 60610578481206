import { PageNames } from '@3as-affiliates/affiliates/web/config';
import {
  ErrorActions,
  useGetAffiliate,
} from '@3as-affiliates/shared/web/data-access/api-client';
import { useAppData } from '@3as-affiliates/shared/web/data-access/data-store';
import { LoadingSpinnerPage } from '@3as-affiliates/shared/web/features';
import {
  AuthenticationState,
  useAuth,
} from '@3as-affiliates/shared/web/utils/auth-provider';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Routes } from '..';
import { HomePage } from '../../pages';

export const RouteWithAffiliateInfo: React.FC = () => {
  const { authState, logOut } = useAuth();

  const { affiliateInfo } = useAppData();
  const { getAffiliate } = useGetAffiliate();

  const [affInfoFetch, setAffInfoFetch] = useState<
    'IN_PROGRESS' | 'SUCCESS' | 'NOT_STARTED' | 'ERROR' | 'SIGN_UP'
  >('NOT_STARTED');

  useEffect(() => {
    const fetchAffiliate = async () => await getAffiliate();

    if (authState === AuthenticationState.LoggedIn) {
      if (!affiliateInfo) {
        setAffInfoFetch('IN_PROGRESS');
        fetchAffiliate().then((res) => {
          if (!res.error) {
            setAffInfoFetch('SUCCESS');
          } else if (res.errorAction === ErrorActions.SIGN_UP) {
            setAffInfoFetch('SIGN_UP');
          } else {
            setAffInfoFetch('ERROR');
            logOut();
          }
        });
      } else {
        setAffInfoFetch('SUCCESS');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliateInfo, authState, logOut]);

  if (authState === AuthenticationState.LoggedOut) {
    return <Navigate to={Routes.LoggedOutLanding} replace={true} />;
  }

  if (affInfoFetch === 'SUCCESS') {
    return <HomePage />;
  } else if (affInfoFetch === 'SIGN_UP') {
    return <Navigate to={Routes.Signup} replace={true} />;
  }

  return <LoadingSpinnerPage pageName={PageNames.LoadingPage} />;
};
