import { isMobileApp } from '@3as-affiliates/affiliates/web/native-app-libs';
import { getUserBrowserInfo } from '@3as-affiliates/shared/web/utils/user-device-info';
import { BrowserType, DeviceOS } from './types';

const browserDetails = getUserBrowserInfo();

export const isMobileSafari = () =>
  browserDetails.BrowserType === BrowserType.MobileSafari;

export const isAndroidDevice = () => browserDetails.Os === DeviceOS.Android;

export const isIosDevice = () => browserDetails.Os === DeviceOS.IOS;

export const isAndroidApp = () => isMobileApp() && isAndroidDevice();

export const isIosApp = () => isMobileApp() && isIosDevice();
