import { ChatBotProvider } from '@3as-affiliates/affiliates/web/features/chat-bot';
import { NativeWebCommunicationListener } from '@3as-affiliates/affiliates/web/native-app-listener';
import { AuthProvider } from '@3as-affiliates/affiliates/web/utils/auth-provider';
import { persistQueryParams } from '@3as-affiliates/affiliates/web/utils/url-utils';
import { AppDataProvider } from '@3as-affiliates/shared/web/data-access/data-store';
import { AnalyticsProvider } from '@3as-affiliates/shared/web/utils/analytics';
import { RouterProvider } from 'react-router-dom';
import GlobalStyle from '../css/globalStyles';
import { environment } from '../environments/environment';
import { appAnalyticsConfig } from '../initializations';
import { router } from '../router';

const App = () => {
  persistQueryParams();

  return (
    <AppDataProvider>
      <AuthProvider
        authOpenIdConfig={environment.authOpenIdConfig}
        isProd={environment.production}
      >
        <AnalyticsProvider appAnalyticsConfig={appAnalyticsConfig}>
          <ChatBotProvider>
            <NativeWebCommunicationListener />
            <GlobalStyle />
            <RouterProvider router={router} />
          </ChatBotProvider>
        </AnalyticsProvider>
      </AuthProvider>
    </AppDataProvider>
  );
};

export default App;
