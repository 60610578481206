import { getCurrentSessionURL, identify, init } from '@fullstory/browser';
import { UpdateAnalyticsDispatcherExtras } from '../provider/types';

interface AdditionalUserInfo {
  Email: string;
  AsurionId: string;
}

interface UpdateFSIdentify {
  (userId: string, additionalUserInfo: AdditionalUserInfo): void;
}

// FullStory integration enabled only in production
export const useFullStory = () => {
  const initFS = (
    isProd: boolean,
    callbackFn: UpdateAnalyticsDispatcherExtras
  ) => {
    isProd &&
      init({ orgId: 'ZVSN6' }, () => {
        const currSessionFullStoryURL = getCurrentSessionURL() || '';
        callbackFn({ FullStoryURL: currSessionFullStoryURL });
      });
  };

  const updateFSIdentify: UpdateFSIdentify = (
    userId: string,
    additionalUserInfo
  ) => identify(userId, additionalUserInfo);

  return { initFS, updateFSIdentify };
};
