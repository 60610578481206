import {
  affiliatesAndroidAppUrl,
  affiliatesIosAppUrl,
} from '@3as-affiliates/affiliates/web/config';
import { openLink } from '@3as-affiliates/affiliates/web/native-app-libs';
import {
  isAndroidDevice,
  isIosDevice,
} from '@3as-affiliates/affiliates/web/utils/user-device-info';
import { useClickAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import styled from 'styled-components';
import { AppleAppStoreBadge, GooglePlayBadge } from '../assets';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 16px;
  gap: 24px;
`;

const AppStoreBadge = styled.img`
  width: 135px;
  height: 40px;
`;

type AppsDownloadButtonsProps = {
  pageName: string;
  showCurrentPlatformAppButtonOnly?: boolean;
  analyticsExtras?: Record<string, string>;
};

type AppsDownloadButtonProps = {
  badgeSrc: string;
  badgeAlt: string;
  Cta: string;
  ActionId: string;
  AppType: 'ios' | 'android';
  appUrl: string;
};

export const AppsDownloadButtons = ({
  pageName,
  showCurrentPlatformAppButtonOnly = false,
  analyticsExtras = {},
}: AppsDownloadButtonsProps) => {
  const { dispatchClickAnalytics } = useClickAnalytics();

  const downloadAppClick = (
    Cta: string,
    ActionId: string,
    AppType: string,
    appUrl: string
  ) => {
    dispatchClickAnalytics(
      {
        Cta,
        ActionId,
      },
      {
        AppType,
        PageName: pageName,
        ...analyticsExtras,
      }
    );
    openLink(appUrl, { preferExternalBrowser: true });
  };

  const AppButton = ({
    badgeAlt,
    badgeSrc,
    Cta,
    ActionId,
    AppType,
    appUrl,
  }: AppsDownloadButtonProps) => (
    <div onClick={() => downloadAppClick(Cta, ActionId, AppType, appUrl)}>
      <AppStoreBadge alt={badgeAlt} src={badgeSrc} />
    </div>
  );

  const showAndroidAppButton = showCurrentPlatformAppButtonOnly
    ? isAndroidDevice()
    : true;
  const showIosAppButton = showCurrentPlatformAppButtonOnly
    ? isIosDevice()
    : true;

  return (
    <Container>
      {showIosAppButton && (
        <AppButton
          badgeAlt="Get it on App Store"
          badgeSrc={AppleAppStoreBadge}
          Cta={'Get it on App Store'}
          ActionId={'appStoreBadge'}
          AppType={'ios'}
          appUrl={affiliatesIosAppUrl}
        />
      )}
      {showAndroidAppButton && (
        <AppButton
          badgeAlt="Get it on Google Play"
          badgeSrc={GooglePlayBadge}
          Cta={'Get it on Google Play'}
          ActionId={'googlePlayBadge'}
          AppType={'android'}
          appUrl={affiliatesAndroidAppUrl}
        />
      )}
    </Container>
  );
};
