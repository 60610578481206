import { breakpoints } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { SectionSubTitle } from '../section-sub-title';
import AmazonRatingsIcon from './assets/amazon-ratings.svg';
import GoogleRatingsIcon from './assets/google-ratings.svg';
import TrsutPilotRatingsIcon from './assets/trust-pilot-ratings.svg';
import { RatingsItem } from './components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  justify-content: center;
`;

const RatingsItemsContainer = styled.div`
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-content: center;
  ${breakpoints.md} {
    grid-template-columns: repeat(auto-fill, 280px);
  }
  margin-top: 28px;
`;

const TrustRatingsItems = [
  {
    icon: GoogleRatingsIcon,
    alt: 'Google ratings',
  },
  {
    icon: TrsutPilotRatingsIcon,
    alt: 'Trust Pilot ratings',
  },
  {
    icon: AmazonRatingsIcon,
    alt: 'Amazon ratings',
  },
];

const renderTrustRatingsItems = () =>
  TrustRatingsItems.map((item, index) => (
    <RatingsItem iconSrc={item.icon} iconAlt={item.alt} key={item.alt} />
  ));

export const TrustSection = () => (
  <Container>
    <SectionSubTitle
      fontSize="20px"
      subTitle="Asurion is trusted by 300 million worldwide."
      textAlign="center"
    />
    <RatingsItemsContainer>{renderTrustRatingsItems()}</RatingsItemsContainer>
  </Container>
);
