import { currentOsAppUrl } from '@3as-affiliates/affiliates/web/config';
import {
  isMobileApp,
  openLink,
  shareLink,
} from '@3as-affiliates/affiliates/web/native-app-libs';
import { isAndroidApp } from '@3as-affiliates/affiliates/web/utils/user-device-info';
import { useAppData } from '@3as-affiliates/shared/web/data-access/data-store';
import { useClickAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import { Button, colors, Drawer } from '@soluto-private/mx-asurion-ui-react';
import { useState } from 'react';
import styled from 'styled-components';

interface ButtonProps {
  size?: 'medium' | 'large';
}

type StartEnrollmentButtonProps = ButtonProps & {
  buttonVariant: 'default' | 'outline';
  analyticsExtras: {
    [key: string]: string;
    PageName: string;
  };
};

const EnrollmentButtonWrapper = styled(Button)`
  width: 100% !important;
  flex: 1;
`;

const EnrollmentDrawer = styled(Drawer)`
  h2 {
    font-size: 24px;
    font-weight: 400 !important;
  }
  button:first-child {
    border: 1px solid ${colors.neutralBright};
  }
  div:first-child {
    padding: 0px !important;
  }
`;

const UnSupportedVersionText = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.neutralDeeper};
  padding: 0px 16px;
`;

const ClickToUpdateLink = styled.span`
  color: ${colors.primaryDeep};
  cursor: pointer;
`;

export const EnrollmentButton = ({
  buttonVariant,
  analyticsExtras,
  size = 'medium',
}: StartEnrollmentButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { dispatchClickAnalytics } = useClickAnalytics();
  const { affiliateInfo, appInfo } = useAppData();

  const affiliateName = `${affiliateInfo?.firstName}_${affiliateInfo?.lastName}`;
  const affiliateCompanyName = affiliateInfo?.companyName;

  const mobileAppVersion = appInfo?.version || '1.0.0';

  // ToDo: Improve this section as a utility function
  const disableShareLink = mobileAppVersion < '1.1.0';

  const startEnrollmentLink = `${process.env['NX_ENROLLMENT_PORTAL_URL_WITH_PARAMS']}${affiliateName}&utm_campaign=${affiliateCompanyName}`;

  const handleButtonClick = () => {
    dispatchClickAnalytics(
      {
        Cta: 'Start Enrollment Button',
        ActionId: 'StartEnrollmentButton',
      },
      analyticsExtras
    );
    if (isMobileApp()) {
      setIsOpen(true);
    } else {
      handleInPersonButtonClick();
    }
  };

  const handleInPersonButtonClick = () => {
    dispatchClickAnalytics(
      {
        Cta: 'In person Button',
        ActionId: 'StartEnrollmentInPerson',
      },
      analyticsExtras
    );

    openLink(startEnrollmentLink);
  };

  const handleShareLinkClick = () => {
    dispatchClickAnalytics(
      {
        Cta: 'Share my sales link',
        ActionId: 'StartEnrollmentShareLink',
      },
      analyticsExtras
    );

    setIsOpen(false);
    let message = `Ready to enroll in Appliance+?`;
    if (isAndroidApp()) {
      message = `${message}: ${startEnrollmentLink}`;
    }
    shareLink(startEnrollmentLink, message);
  };

  const updateAppClick = () => {
    dispatchClickAnalytics(
      {
        Cta: 'Click here to update',
        ActionId: 'AppUpdateLink',
      },
      {
        ...analyticsExtras,
      }
    );
    openLink(currentOsAppUrl, { preferExternalBrowser: true });
  };

  return (
    <>
      <EnrollmentButtonWrapper
        size={size}
        variant={buttonVariant}
        onClick={handleButtonClick}
      >
        Start enrollment
      </EnrollmentButtonWrapper>
      <EnrollmentDrawer
        className="enrollment-drawer"
        open={isOpen}
        onOpenUpdate={() => setIsOpen(false)}
        title={'How would you like to sell Appliance+?'}
        footer={{
          primaryButton: {
            children: 'In person',
            onClick: handleInPersonButtonClick,
          },
          secondaryButton: {
            children: 'Share my sales link',
            variant: 'flat',
            onClick: handleShareLinkClick,
            disabled: disableShareLink,
          },
          view: 'one-above-one',
        }}
      >
        {disableShareLink && (
          <UnSupportedVersionText>
            Please update to the latest version of the app to enable "Share my
            sales link". &nbsp;
            <ClickToUpdateLink onClick={updateAppClick}>
              Click here to update
            </ClickToUpdateLink>
          </UnSupportedVersionText>
        )}
      </EnrollmentDrawer>
    </>
  );
};
