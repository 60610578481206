import { SectionSubTitle } from '../section-sub-title';
import { SectionTitle } from '../section-title';
import { AppsDownloadButtons } from './AppsDownloadButtons';

export const DownloadAppWithTitleSubTitle = ({
  pageName,
}: {
  pageName: string;
}) => {
  return (
    <>
      <SectionTitle title={'Welcome to Asurion Affiliates!'} />
      <SectionSubTitle subTitle="Download the Affiliate app to log in and access your personalized affiliate link." />
      <AppsDownloadButtons pageName={pageName} />
    </>
  );
};
