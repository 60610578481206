import { ComponentTypes } from '@3as-affiliates/shared/types-configs';
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import type { AnalyticsExtras } from '../provider';
import { useAnalytics } from './useAnalytics';

interface ViewProps {
  ComponentType: ComponentTypes;
  ComponentId: string;
}

export const useViewAnalytics = (
  viewProps: ViewProps,
  analyticsData: AnalyticsExtras = {}
) => {
  const [elementRef, inView] = useInView({ threshold: 0.5, triggerOnce: true });
  const { dispatch } = useAnalytics();
  const didFireAnalytic = useRef(false);
  useEffect(() => {
    if (!didFireAnalytic.current && inView) {
      dispatch('View', { ...viewProps, ...analyticsData });
      didFireAnalytic.current = true;
    }
  }, [inView, dispatch, analyticsData, didFireAnalytic, viewProps]);
  return {
    elementRef,
  };
};
