import { ComponentTypes } from '@3as-affiliates/shared/types-configs';
import { useViewAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import { ReactNode } from 'react';
import { PageContainer } from './PageLayoutComponent';

interface PageContainerWithViewAnalyticsProps {
  children: ReactNode;
  pageName: string;
}

export const PageContainerWithViewAnalytics = ({
  children,
  pageName,
}: PageContainerWithViewAnalyticsProps) => {
  const { elementRef } = useViewAnalytics({
    ComponentType: ComponentTypes.Page,
    ComponentId: pageName,
  });

  return <PageContainer ref={elementRef}>{children}</PageContainer>;
};
