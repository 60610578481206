import {
  affiliateQueryParamKeys,
  AffiliateSessionStoreKey,
  allQueryParamKeys,
  appQueryParamKeys,
} from './config';

interface QueryObject {
  [key: string]: string;
}

export const persistQueryParams = () => {
  try {
    const urlQueryParams = window.location.search;

    if (urlQueryParams) {
      const searchParamObj = new URLSearchParams(urlQueryParams);

      const queryParams = allQueryParamKeys.reduce<string>((acc, key) => {
        const val = searchParamObj.get(key);
        if (val) {
          acc += `${key}=${val}&`;
        }
        return acc;
      }, '');

      if (queryParams) {
        const encodedValString = btoa(queryParams);
        sessionStorage.setItem(
          AffiliateSessionStoreKey.AffUrlData,
          encodedValString
        );
      }
    }
  } catch (err) {
    console.error(err);
  }
};

export const readSavedQueryParams = (
  queryKeys: Array<string>
): QueryObject | undefined => {
  try {
    const encodedData = sessionStorage.getItem(
      AffiliateSessionStoreKey.AffUrlData
    );
    if (encodedData) {
      const decodedData = atob(encodedData);
      const parsedQueryParamData = new URLSearchParams(decodedData);

      const savedData = queryKeys.reduce<Record<string, string>>((acc, key) => {
        const val = parsedQueryParamData.get(key);
        if (val) {
          acc[key] = val;
        }
        return acc;
      }, {});

      return savedData;
    }
    return {};
  } catch (err) {
    console.error(err);
  }
};

export const getAffiliateQueryParams = (): Record<string, string> =>
  readSavedQueryParams(affiliateQueryParamKeys) || {};

export const getAppInfoQueryParams = () =>
  readSavedQueryParams(appQueryParamKeys) || {};
