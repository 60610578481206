import { useAuth } from '@3as-affiliates/shared/web/utils/auth-provider';

export const useApiCommons = () => {
  const { getAuthUserInfo } = useAuth();

  const getCommonHeaders = async () => {
    const { accessToken } = await getAuthUserInfo();
    if (accessToken) {
      return { Authorization: `Bearer ${accessToken}` };
    } else {
      throw new Error('No access token found');
    }
  };
  return { getCommonHeaders };
};
