import {
  Footer,
  Header,
  LoadingSpinner,
  PageContainerWithViewAnalytics,
} from '@3as-affiliates/shared/web/components';
import {
  AuthenticationState,
  useAuth,
} from '@3as-affiliates/shared/web/utils/auth-provider';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

type Props = {
  pageName: string;
  homePageRoute: string;
};

export const AuthRedirectCallback = ({ pageName, homePageRoute }: Props) => {
  const { handleWebAppLoginCallback, authState } = useAuth();

  useEffect(() => {
    handleWebAppLoginCallback();
  }, [handleWebAppLoginCallback]);

  const routeWithAuthState = () => {
    if (authState === AuthenticationState.LoggedIn) {
      return <Navigate to={homePageRoute} replace={true} />;
    }

    return <LoadingSpinner />;
  };

  return (
    <PageContainerWithViewAnalytics pageName={pageName}>
      <Header pageName={pageName} />
      {routeWithAuthState()}
      <Footer />
    </PageContainerWithViewAnalytics>
  );
};
