import {
  nativeAppLogin,
  nativeAppLoginRefresh,
} from '@3as-affiliates/affiliates/web/native-app-libs';
import {
  AuthContext,
  AuthenticationState,
  AuthProviderProps,
  AuthUserProfile,
  MobileAppAuthResponseType,
  parseJWTToken,
} from '@3as-affiliates/shared/web/utils/auth-provider';
import React, { useCallback, useEffect, useState } from 'react';

const ASURION_AFFILIATES_LOCALSTORAGE_KEY_BASE = 'asurionAffiliates.user.';

export const MobileAppAuthProvider: React.FC<AuthProviderProps> = ({
  isProd,
  children,
}) => {
  console.log('MobileAppAuthProvider');
  const localstorageDataKey = `${ASURION_AFFILIATES_LOCALSTORAGE_KEY_BASE}${
    isProd ? 'prod' : 'dev'
  }`;

  const [authState, setAuthState] = useState<AuthenticationState>(
    AuthenticationState.LoggedOut
  );
  const [authUserInfo, setAuthUserInfo] = useState<AuthUserProfile | null>(
    null
  );

  const logIn = () => nativeAppLogin();

  const logOut = () => {
    localStorage.removeItem(localstorageDataKey);
    setAuthUserInfo(null);
    setAuthState(AuthenticationState.LoggedOut);
  };

  const updateMobileAppAuthState = (
    authData: MobileAppAuthResponseType | undefined
  ) => {
    if (!authData) {
      setAuthState(AuthenticationState.LoggedOut);
      return;
    }
    try {
      const { accessToken, accessTokenExpirationDate, refreshToken, idToken } =
        authData;
      const parsedData = parseJWTToken(idToken);

      localStorage.setItem(
        localstorageDataKey,
        JSON.stringify({
          accessToken,
          accessTokenExpirationDate,
          refreshToken,
          idToken,
        })
      );

      setAuthUserInfo({ ...parsedData, accessToken });
      setAuthState(AuthenticationState.LoggedIn);
    } catch (error) {
      console.log('updateMobileAppAuthState error', error);
      setAuthState(AuthenticationState.LoggedOut);
    }
  };

  const getAuthUserInfo = useCallback(async () => {
    const {
      asurionId = '',
      email = '',
      accessToken = '',
      personas = [],
    } = authUserInfo || {};
    return {
      asurionId,
      email,
      accessToken,
      personas,
    };
  }, [authUserInfo]);

  useEffect(() => {
    try {
      const localAuthData = localStorage.getItem(localstorageDataKey) || '{}';
      const { refreshToken } = JSON.parse(localAuthData);
      if (refreshToken) {
        nativeAppLoginRefresh(refreshToken);
      }
    } catch (error) {
      console.log('unable to get local auth data', error);
    }
  }, [localstorageDataKey]);

  // Dummy function only to support web app auth provider; this will not be used in mobile app auth provider; will be fixed in upcoming PRs
  const handleWebAppLoginCallback = () => {
    console.log('handleWebAppLoginCallback');
  };

  const value = {
    authState,
    getAuthUserInfo,
    authUserInfo,
    logIn,
    logOut,
    updateMobileAppAuthState,
    handleWebAppLoginCallback,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
