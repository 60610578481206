import { showMobileAppInstallPrompts } from '@3as-affiliates/affiliates/web/utils/user-device-info';
import { Button } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { DownloadAppWithTitleSubTitle } from '../download-app';
import { SectionSubTitle } from '../section-sub-title';
import { SectionTitle } from '../section-title';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 8px 0;
`;

export const BadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface LoginBannerProps {
  onLoginButtonClick: () => void;
  pageName: string;
}

export const LoginBanner = ({
  onLoginButtonClick,
  pageName,
}: LoginBannerProps) => {
  return (
    <Container>
      {showMobileAppInstallPrompts ? (
        <DownloadAppWithTitleSubTitle pageName={pageName} />
      ) : (
        <>
          <SectionTitle title={'Congratulations!'} />
          <SectionSubTitle
            subTitle="Welcome to Asurion Affiliates! Now that training’s done, are you ready
        to get started?"
          />
          <SectionSubTitle
            subTitle="Log in to your account to access your personalized affiliate link and
        start selling."
          />
          <Button
            style={{ margin: '24px 16px 32px 16px' }}
            onClick={onLoginButtonClick}
          >
            Sign in
          </Button>
        </>
      )}
    </Container>
  );
};
