import { Text } from '@soluto-private/mx-asurion-ui-react';
import styled from 'styled-components';
import { AppsDownloadButtons } from './AppsDownloadButtons';
import AppOnDevice from './assets/app-on-device.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 16px 32px 16px;
`;

const AppHomeImage = styled.img`
  width: 58%;
  max-width: 450px;
  margin: 8px 0px;
`;

export const DownloadAppWithDeviceImage = ({
  pageName,
}: {
  pageName: string;
}) => {
  return (
    <Container>
      <Text size={4} weight={'feather'} style={{ marginBottom: '24px' }}>
        Download the app to get started
      </Text>
      <AppHomeImage
        src={AppOnDevice}
        alt="Mobile device with Asurion Affiliates homepage in display"
      />
      <AppsDownloadButtons pageName={pageName} />
    </Container>
  );
};
