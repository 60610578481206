import { isMobileApp } from '@3as-affiliates/affiliates/web/native-app-libs';
import { getAppInfoQueryParams } from '@3as-affiliates/affiliates/web/utils/url-utils';
import { AppPlatforms } from '@3as-affiliates/shared/types-configs';
import { AppAnalyticsConfig } from '@3as-affiliates/shared/web/utils/analytics';
import { environment } from '../environments/environment';

const AppPlatform = isMobileApp() ? AppPlatforms.MobileApp : AppPlatforms.Web;
const appInfoQueryParams = getAppInfoQueryParams();

export const appAnalyticsConfig: AppAnalyticsConfig = {
  scope: environment.analyticsScope,
  isProd: environment.production,
  defaultExtraData: {
    Partner: environment.partner,
    AppPlatform,
    ...appInfoQueryParams,
  },
};
