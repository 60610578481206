export enum AffiliateTypeformKeys {
  FirstName = 'aff_fname',
  LastName = 'aff_lname',
  PhoneNumber = 'aff_phone',
  CompanyName = 'aff_company',
  Role = 'aff_role',
  State = 'aff_state',
}

export const affiliateQueryParamKeys = Object.values(AffiliateTypeformKeys);

enum AppInfo {
  'cid' = 'cid',
}
export const appQueryParamKeys = Object.values(AppInfo);

export const allQueryParamKeys: Array<string> = [
  ...affiliateQueryParamKeys,
  ...appQueryParamKeys,
];

export enum AffiliateSessionStoreKey {
  AffUrlData = 'affUrlData',
}
