import { formatSpaceInUrl } from '@3as-affiliates/affiliates/web/utils/url-utils';
import { isMobileApp } from '../utils/mobileAppCheck';

export type OpenLinkOptions = {
  preferExternalBrowser?: boolean;
};

export function openLink(urlParam: string, options?: OpenLinkOptions) {
  const url = formatSpaceInUrl(urlParam);

  function openLinkPayload(): string {
    return JSON.stringify({
      transactionId: crypto.randomUUID(),
      body: JSON.stringify({
        query: `
          mutation openLink($url: String!, $options: openLinkOptions) { 
            openLink(url: $url, options: $options) 
          }`,
        variables: {
          url,
          options,
        },
      }),
    });
  }

  if (isMobileApp()) {
    // eslint-disable-next-line
    // @ts-ignore-next-line
    window.ReactNativeWebView.postMessage(openLinkPayload());
  } else {
    window.open(url, '_blank');
  }
}
