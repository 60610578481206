import { DeleteAccountModal } from '@3as-affiliates/affiliates/web/features/delete-account';
import { useClickAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import { colors } from '@soluto-private/mx-asurion-ui-react';
import { useState } from 'react';
import styled from 'styled-components';

const Anchor = styled.div`
  color: ${colors.black};
  margin-left: 4px;
  line-height: 24px;
  letter-spacing: 0px;
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
`;

export const DeleteAccountLink = () => {
  const { dispatchClickAnalytics } = useClickAnalytics();

  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);

  const handleClick = () => {
    dispatchClickAnalytics({
      Cta: 'Delete Account Footer Link Clicked',
      ActionId: 'deleteAccountFooterLinkClicked',
    });
    setShowDeleteAccountModal(true);
  };

  return (
    <Anchor
      onClick={handleClick}
      data-testid="footer-delete-account-link"
      aria-describedby="delete-account-warning"
      role="link"
    >
      Delete account
      <DeleteAccountModal
        showDeleteAccountModal={showDeleteAccountModal}
        hideDeleteAccountModal={() => setShowDeleteAccountModal(false)}
      />
    </Anchor>
  );
};
