import { AlertBanner as AUIAlertBanner } from '@soluto-private/mx-asurion-ui-react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const AlertBannerContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  button {
    font-weight: 400;
  }
`;

interface AlertBannerProps {
  isOpen: boolean;
  bannerType: 'default' | 'dark' | 'error' | 'warn' | 'success' | undefined;
  onAlertClose?: () => void;
  iconSrc?: string;
  bottomButton?: {
    buttonRole: string;
    onClick: () => void;
    text: string;
  };
  children: JSX.Element | string;
}

export const AlertBanner = (props: AlertBannerProps) => {
  const { isOpen, bannerType, iconSrc, children, onAlertClose, bottomButton } =
    props;

  const [show, setShow] = useState(isOpen);

  useEffect(() => {
    setShow(isOpen);
  }, [isOpen]);

  const handleAlertBannerClose = () => {
    setShow(false);
    if (onAlertClose) {
      onAlertClose();
    }
  };

  return (
    <AlertBannerContainer>
      <AUIAlertBanner
        isOpen={show}
        alertBannerType={bannerType}
        iconSrc={iconSrc}
        onCloseClick={handleAlertBannerClose}
        button={bottomButton}
      >
        {children}
      </AUIAlertBanner>
    </AlertBannerContainer>
  );
};
