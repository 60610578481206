import { useClickAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import { Link, Text } from '@soluto-private/mx-asurion-ui-react';
import { useNavigate } from 'react-router-dom';

type MsaLabelWithLinkProps = {
  pageName: string;
};

export const MsaLabelWithLink = ({ pageName }: MsaLabelWithLinkProps) => {
  const { dispatchClickAnalytics } = useClickAnalytics();
  const navigate = useNavigate();

  const msaLinkClick = () => {
    dispatchClickAnalytics(
      {
        Cta: 'MSA Link',
        ActionId: 'MSALink',
      },
      {
        PageName: pageName,
      }
    );
    navigate('/msa');
  };

  return (
    <Text>
      By continuing, you agree to our{' '}
      <Link onClick={msaLinkClick}>Master Services Agreement</Link>
    </Text>
  );
};
