import { openLink } from '@3as-affiliates/affiliates/web/native-app-libs';
import { Footer } from '@3as-affiliates/shared/web/components';
import { DeleteAccountLink } from './DeleteAccountLink';

export const FooterWithDeleteAccount = () => {
  const customClickHandler = (url: string) =>
    openLink(url, { preferExternalBrowser: true });

  return (
    <Footer
      AdditionalLinkComponent={DeleteAccountLink}
      customClickHandler={customClickHandler}
    />
  );
};
