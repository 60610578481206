import {
  AuthenticationState,
  useAuth,
} from '@3as-affiliates/shared/web/utils/auth-provider';
import { getUserBrowserInfo } from '@3as-affiliates/shared/web/utils/user-device-info';
import { createContext, useContext, useEffect, useState } from 'react';
import { createRootDispatcher } from 'shisell';
import {
  withExtras,
  withIdentities,
  withIdentity,
  withMeta,
} from 'shisell/extenders';
import { useFullStory } from '../hooks/useFullStory';
import { registerLocalWriters } from '../writers';
import {
  AnalyticsContextValueProps,
  AnalyticsExtras,
  AnalyticsProviderProps,
  ExtendDispatcherExtra,
} from './types';

const AnalyticsContext = createContext<AnalyticsContextValueProps | undefined>(
  undefined
);

export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('Analytics context error');
  }
  return context;
};

export const AnalyticsProvider = ({
  children,
  appAnalyticsConfig: { scope, isProd = false, defaultExtraData = {} },
}: AnalyticsProviderProps) => {
  const { authState, getAuthUserInfo } = useAuth();

  const { initFS, updateFSIdentify } = useFullStory();

  const [extendDispatcherExtra, setExtendDispatcherExtra] =
    useState<ExtendDispatcherExtra>({});
  const [AsurionId, setAsurionId] = useState('');
  const [Email, setEmail] = useState('');

  // this function can be used to extend extra properties to default dispatcher
  const updateAnalyticsDispatcherExtras = (data: AnalyticsExtras) => {
    setExtendDispatcherExtra({
      ...extendDispatcherExtra,
      ...data,
    });
  };

  // init-ing FullStory
  initFS(isProd, updateAnalyticsDispatcherExtras);

  useEffect(() => {
    if (authState === AuthenticationState.LoggedIn) {
      getAuthUserInfo().then(({ asurionId, email }) => {
        setAsurionId(asurionId);
        setEmail(email);
      });
    }
  }, [authState, getAuthUserInfo]);

  useEffect(() => {
    const { FullStoryURL } = extendDispatcherExtra;
    if (authState === AuthenticationState.LoggedIn && FullStoryURL) {
      updateFSIdentify(AsurionId, {
        Email,
        AsurionId,
      });
    }
  }, [authState, updateFSIdentify, extendDispatcherExtra, AsurionId, Email]);

  // init-ing analytics with writers and user browser defaults. this also registers the writers with shisell
  const userBrowserData = getUserBrowserInfo();
  const writer = registerLocalWriters(isProd);

  const dispatcher = createRootDispatcher(writer).extend(
    withIdentity('UserBrowserId', userBrowserData.UserBrowserId),
    withIdentities({ AsurionId }),
    withExtras(userBrowserData),
    withExtras(defaultExtraData),
    withExtras(extendDispatcherExtra),
    withMeta('isTest', !isProd),
    withMeta('_eventType', 'event')
  );

  return (
    <AnalyticsContext.Provider
      value={{
        scope,
        dispatcher,
        updateAnalyticsDispatcherExtras,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
